@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap');

/*PMD Todays Goal*/
.ui.move.reveal .hidden.content {
    -webkit-transform: translateX(-100%) !important;
    -ms-transform: translateX(-100%) !important;
    transform: translateX(-100%) !important;
}

.ui.move.reveal:hover .hidden.content {
    -webkit-transform: translateX(1%) !important;
    -ms-transform: translateX(1%) !important;
    transform: translateX(1%) !important;
}

.ui.move.reveal > .content {
    display: block !important;
}

.clickable {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.btn.primaryBtn {
    background: #1a89a7;
    color: #fff;
}


/* PMD */
.schedule-coordinator-today {
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0;
}

.mco-list-30-days {
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0;
}

.billing-all-time {
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0;
}

.schedule-today {
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0;
}

.production {
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0;
    font-family: 'SegoeUI-Semibold' !important;
    background: rgba(255, 246, 237, 1) !important;
    border: none !important;
    overflow-wrap: break-word !important;
}

.ui.fluid.card.card .content {
    border: none !important;
}

.ui.bottom.attached production_todaysgoal_display {
    border: none !important;
    background: none !important;
}

.production_todaysgoal_display {
    background: none !important;
}

.production_right_label {
    color: #FFFFFF !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0;
    font-family: 'SegoeUI-Semibold' !important;
    background: rgba(215, 150, 50, 1) !important;
    overflow-wrap: break-word !important;
}

.open_dental_connection_right_label {
    color: #FFFFFF !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0;
    font-family: 'SegoeUI-Semibold' !important;
    background: #FFFFFF !important;
    overflow-wrap: break-word !important;
}

.production_left_label {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0;
    font-family: 'SegoeUI-Semibold' !important;
    background: rgba(255, 246, 237, 1) !important;
    overflow-wrap: break-word !important;
}

.lightorangebg {
    background: rgba(255, 246, 237, 1) !important;
    color: #000000 !important;
}

.ui.basic.blue.left.pointing.label.lightorangebg {
    background: rgba(255, 246, 237, 1) !important;
    color: #000000 !important;
}

.pmd_numbers_top {
    background: #F5F7FA 0% 0% no-repeat padding-box;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
}

.pmd_numbers_above {
    background: #f6f8fc 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
}

.pmd_numbers_below {
    background: #ECEEF1 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
}

.pmd_numbers_below_community_outreach_won {
    background: #35BA9B 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
}

.pmd_numbers_below_community_outreach_lost {
    background: #EB5463 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
}

.pmd_color_below_community_outreach_lost {
    color: red !important;
}

.pmd_color_below_community_outreach_won {
    color: green !important;
}

.pmd_tbl_no_border {
    border: none !important;
}

.ui.unstackable.table.pm {
    border: none !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.practice-manager-dashboard {
    color: #262626;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 0;
}

.pmrm {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 0;
}

.boldCont {
    font-size: 14px;
    font-weight: bold;
}

.tbl-header {
    color: #D48135;
    font-size: 14px;
    font-weight: bold;
}

.tbl-bold {
    font-size: 14px;
    font-weight: bold;
}

.dr-name-dr-damiel-yoon-dr-john {
    height: 170px;
    width: 98px;
    color: #D48135;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
}

.table-holder-copy {
    box-sizing: border-box;
    height: 201px;
    width: 293px;
    border: 1px solid #E1E1E1;
    background-color: #FFFFFF;
}

.card {
    background-color: #FFFFFF;
}

.strip-copy {
    height: 39px;
    width: 291px;
    background-color: #F5F5F5;
}


@font-face {
    font-family: 'SegoeUI-Regular';
    src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.eot');
    src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.svg') format('svg'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.woff') format('woff'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUI-Semibold';
    src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.eot');
    src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.svg') format('svg'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.woff') format('woff'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUI-Bold';
    src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.eot');
    src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.svg') format('svg'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.woff') format('woff'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    color: #262626;
    font-family: 'SegoeUI-Regular' !important;
    font-size: 14px !important;
    background: #ebeef1;
}

b,
strong {
    font-weight: normal !important;
    font-family: 'SegoeUI-Semibold' !important;
}

#root,
#root > div {
    height: 100%;
}

.ui.table.tableStyle thead th header-os:hover {
    background: yellow;
}

a,
div,
span,
h4 {
    transition: all 0.2s ease-in-out !important;
}

/*------------------------------------------------Margin---------------------------------------------*/
.mt0 {
    margin-top: 0 !important;
}

.mt5 {
    margin-top: 5px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mt50 {
    margin-top: 50px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.mr20 {
    margin-right: 20px !important;
}

.mr30 {
    margin-right: 30px !important;
}

.mb0 {
    margin-bottom: 0 !important;
}

.mb5 {
    margin-bottom: 5px !important;
}

.mb6 {
    margin-bottom: 6px !important;
}

.mb7 {
    margin-bottom: 7px !important;
}

.mb8 {
    margin-bottom: 8px !important;
}

.mb9 {
    margin-bottom: 9px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.ml15 {
    margin-left: 15px !important;
}

.ml20 {
    margin-left: 20px;
}

.f12 {
    font-size: 12px !important;
}

.no-wrap {
    white-space: nowrap;
}

.clearfix {
    clear: both;
}

/*----------------------------------------------Padding-----------------------------------*/
.pd0 {
    padding: 0 !important;
}

.pb0 {
    padding-bottom: 0 !important;
}

.py10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pb10 {
    padding-bottom: 10px !important;
}

/*----------------------------------------------Other-----------------------------------*/
.removeAclr {
    color: #273140 !important;
}

.notification {
    position: relative;
    margin-right: 20px;
}

.notification .bellIcon {
    font-size: 16px !important;
    margin-top: 20px !important;
}

.notification .ui.label.circular.bellIconLabel {
    background: #d16f26 !important;
    color: #fff !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    position: absolute;
    min-height: 19px !important;
    min-width: 19px !important;
    line-height: 8px !important;
    left: 9px;
    top: 10px;
    padding: 5px !important;
}

.helpMark {
    margin: -15px 20px 0 10px;
    float: right;
}

.right {
    float: right !important;
}

.zoom-80 {
    zoom: 80%
}

.overflowX {
    width: 100%;
    overflow-x: auto;
    max-height: 600px;
}

.cursorPointer {
    cursor: pointer !important;
}

.displayInline {
    display: inline !important;
}

.displayInlineBlock {
    display: inline-block !important;
}

.displayBlock {
    display: block !important;
}

.word-break {
    word-break: break-word !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.textCenter {
    text-align: center !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.widthFluid {
    width: 100% !important;
}

.widthFluidz {
    width: 100% !important;
    padding-right: 10px;
}

.widthFluidi {
    width: 100% !important;
    padding-left: 10px;
}

.borderTop {
    border-top: 0px;
}

.paddingFluid {
    padding-bottom: 2px;
}

.no-wrap {
    white-space: nowrap;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline !important;
}

.ui.search.dropdown.validationStatus .menu {
    max-height: max-content;
}

/*.icon{*/
/*    color: #97999e !important;*/
/*}*/
a.link {
    margin: 0 20px 10px 0;
    color: #262626;
    display: inline-block;
}

a.link:hover .icon {
    color: #d16f26 !important;
}

.text-danger {
    color: #DB2828;
    margin-top: -13px !important;
    float: left;
    font-size: 13px;
}

.text-danger-modal {
    color: #DB2828;
    margin-top: -13px !important;
    float: center;
}

.text-modal {
    color: #141414;
    margin-top: -13px !important;
    float: center;
}

.flex {
    display: flex;
}

.items-end {
    align-items: end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.items-stretch {
    align-items: stretch;
}

.labelStyle {
    font-family: "SegoeUI-Semibold", Arial, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
}

.labelTop {
    position: relative;
    margin-top: 5px;
}

.labelTop label {
    position: absolute;
    background: #fff;
    margin-left: 5px;
    top: -8px;
    z-index: 1;
    font-size: 11px;
    padding: 0 4px;
}

.w-full {
    width: 100% !important;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper div {
    width: 100%;
}

/*--------------------------------------------Bg-Color--------------------------------------*/
.bgLightGray {
    background: #dfebef !important;
}

/*--------------------------------------------Text-Color--------------------------------------*/
.bodyColor {
    color: #262626 !important;
}

.primaryColor {
    color: #d16f26 !important;
}

.red {
    color: #DB2828;
}

/*--------------------------------------------Dimmer--------------------------------------*/
.ui.dimmer {
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.ui.dimmer .ui.loader:after {
    border-color: #fff transparent transparent !important;
}

.ui.dimmer .ui.loader {
    color: #fff !important;
}

/*---------------------------------------------------------------Buttons------------------------------------------------*/
.ui.button {
    font-family: 'SegoeUI-Regular' !important;
    font-weight: normal !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 14px !important;
}

.ui.button.primary {
    background-color: #d16f26 !important;
}

.ui.primary.button:hover {
    background-color: #c4631b !important;
}

.ui.button.secondary {
    background-color: #555 !important;
}

.ui.secondary.button:hover {
    background-color: #444 !important;
}

.dateFilers .ui.button {
    background: #f7f7f7;
}

.dateFilers .ui.button:hover {
    background: #eee;
}

/*---------------------------------------------------------------Heading------------------------------------------------*/
h2 {
    font-family: 'SegoeUI-Semibold';
    font-size: 22px;
}

h3 {
    font-family: 'SegoeUI-Semibold';
    font-size: 20px;
}

h5 {
    font-family: 'SegoeUI-Semibold';
    font-size: 16px;
}

/*----------------------------------------------topFilters-------------------------------------------------------*/
.topFilters {
    float: right;
}

.topFilters > div,
.topFilters button {
    margin-right: 10px !important;
    margin-bottom: 15px !important;
}

.topFilters > *:last-child {
    margin-right: 0 !important;
}

.topFilterz {
    float: left;
    display: flex;
}

.pmdRMPM {
    float: left;
}

/*---------------------------------------------------------------Login------------------------------------------------*/
.login,
.bgShape {
    height: 100%;
    float: left;
    width: 100%;
}

.login {
    background-image: url('../images/bgTexture.jpg');
}

.login .bgShape {
    background: url("../images/bgShape.png") no-repeat;
    background-size: contain;
}

.login button.google {
    margin: 30px 0 90px;
    border-radius: 100px !important;
    overflow: hidden;
    font-size: 17px !important;
    font-weight: bold !important;
    padding: 5px 15px !important;
    box-shadow: 0px 2px 10px #ccc !important;
}

.login .ui.label {
    background: transparent;
    font-size: 20px;
    font-weight: normal;
    color: #323232;
}

.loginLogo {
    padding-top: 100px;
}

.practiceSlider {
    width: 600px;
    /*height: 400px;*/
    margin: 0 auto;
    position: relative;
}

.practiceSlider .practiceImage {
    width: 500px;
    margin-bottom: 15px;
    height: 400px;
}

.practiceSlider .next-btn,
.practiceSlider .prev-btn {
    font-size: 40px;
    position: absolute;
    top: 44%;
    color: #fff;
    width: 40px;
    cursor: pointer;
    opacity: 0.5;
}

.practiceSlider .next-btn .icon,
.practiceSlider .prev-btn .icon {
    color: #000 !important;
}

.practiceSlider .next-btn {
    right: -40px;
}

.practiceSlider .prev-btn {
    left: -40px;
}

.practiceSlider .next-btn:hover,
.practiceSlider .prev-btn:hover {
    opacity: 1;
}

/*-------------------------------------------------------------------Header-----------------------------------------*/
.logoHolder {
    background: #fff;
    border-radius: 0 15px;
}

.cdp-udr .ui.thin.left.sidebar.visible .logoHolder {
    margin: 15px;
}

.cdp-udr .ui.basic.segment .ui.secondary.menu {
    background: #fff;
    height: 60px;
    color: #273140;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0px 2px 8px #ddd;
    margin: 0;
    width: 100%;
}

.cdp-udr .ui.secondary.menu .item {
    color: #273140;
    padding: 21px 25px;
    margin: 0;
}

.cdp-udr .ui.secondary.menu .item.toggle {
    padding: 20px 22px;
    border-radius: 0;
    background: #fff;
}

.cdp-udr .ui.secondary.menu .item.toggle:hover {
    background: #ebeef1;
}

.cdp-udr .ui.secondary.menu .item.toggle .icon {
    font-size: 20px;
    color: #d16f26 !important;
}

.cdp-udr .ui.secondary.menu .item:hover {
    color: #273140 !important;
}

.cdp-udr .ui.secondary.menu .icon.setting {
    width: auto;
}

.cdp-udr .ui.secondary.menu .ui.dropdown:hover {
    background: #fff;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu {
    border-radius: 0 0 4px 4px !important;
    left: -120px !important;
    margin-top: 0 !important;
    width: 195px;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu .menu {
    margin-top: 35px !important;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu .item {
    border-radius: 0;
}

.cdp-udr .ui.secondary.menu .menu .item.innerDropdown {
    padding: 0 !important;
}

.cdp-udr .ui.secondary.menu .menu .item.innerDropdown .menu {
    font-size: .9rem !important;
}

.cdp-udr .ui.secondary.menu .item.innerDropdown > div:first-child {
    padding: 0.78571429em 1.14285714em !important;
    width: 100%;
}

.cdp-udr .ui.secondary.menu .ui.dropdown .menu .item + .item {
    border-top: 1px solid #e1e1e1;
}

.cdp-udr .ui.secondary.menu .dropdown.user {
    padding: 17px 16px;
}

.cdp-udr .ui.secondary.menu .dropdown.user .text {
    margin-top: 4px;
}

.cdp-udr .ui.secondary.menu .ui.floating.label {
    top: 7px !important;
    left: 52px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    padding: 4px !important;
}

.cdp-udr .ui.secondary.menu .ui.compact.menu {
    background: none;
    border: none;
    box-shadow: none;
}

.cdp-udr .ui.menu .right.menu .dropdown:last-child .menu {
    right: 5px !important;
}

.cdp-udr .ui.secondary.menu .logoSmall {
    margin: 8px 0;
    display: none;
}

/*-----------------------------------------------------------Form------------------------------------------------*/
.ui.form.formStyle label {
    display: block;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.92857143em;
    font-family: 'SegoeUI-Semibold';
    text-transform: none;
}

input:focus,
textarea:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
    border-color: #d16f26 !important;
}

.ui.selection.active.dropdown:hover {
    border-color: #d16f26 !important;
}

.ui.form.formStyle .ui.checkbox label {
    font-family: 'SegoeUI-Regular';
}

.ui.form .fields.mb0 {
    margin-bottom: 0 !important;
}

/*-----------------------------------------------------------datePicker------------------------------------------------*/
.datePicker {
    display: inline-block;
    position: relative;
}

.datePicker .react-datepicker {
    box-shadow: 0px 0px 12px #bbb;
    border: none !important;
}

.datePicker input {
    border: 1px solid #dededf;
    border-radius: 4px;
    padding: 0.67857143em 2.1em 0.67857143em 1em;
    height: 38px;
    outline: none;
}

.datePicker .icon.calendar {
    position: absolute;
    right: 10px;
    top: 9px;
}

.datePicker .react-datepicker__day--keyboard-selected {
    background-color: #d16f26 !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.datePicker-holder {
    width: 100px;
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
}

.datePicker-holder input {
    width: 80px;
}

.datePicker-holder input:focus-visible {
    outline: none;
}

/*-----------------------------------------------------------Landing------------------------------------------------*/
.landing {
    height: 100%;
}

.landing > .ui.grid {
    margin-top: 50px;
}

.landing .ui.button {
    width: 100%;
    height: 100%;
    font-size: 16px;
    background: transparent;
    padding: 10px !important;
    margin: 0;
}

.landing .ui.grid > .row,
.landing .ui.grid > .column:not(.row) {
    padding-top: 0;
    padding-bottom: 0;
}

.landing .iconHolder .icon {
    font-size: 22px;
    color: #d16f26 !important;
    margin-right: 0 !important;
    margin-bottom: 5px;
}

.landing .iconHoldergrey .icon {
    color: #808080 !important;
    font-size: 22px;
}

.landing .iconHolder.medium,
.landing .iconHoldergrey.medium {
    width: 75px;
    height: 76px;
    padding: 28px 0;
    background-size: contain;
}


.landing .iconHolder.medium .icon,
.landing .iconHoldergrey.medium .icon {
    font-size: 27px;
}

.landing .iconHolder.large,
.landing .iconHoldergrey.large {
    width: 90px;
    height: 90px;
    background-size: contain;
    padding: 30px 0;
}

.landing .iconHolder.large .icon,
.landing .iconHoldergrey.large .icon {
    font-size: 34px;
}

.appIcons .card {
    height: 100px !important;
    float: left;
    padding: 0 !important;
    margin: 0 20px 20px 0;
    border-radius: 4px;
    text-align: center;
    position: relative;
}

.appIcons .card > a {
    position: relative;
}

.appIcons .card > a .innerContent {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
}

.appIcons .card .icon {
    margin-right: 0;
}

.ui.nine.doubling.cards.appIcons {
    margin-left: -0.75em;
    margin-right: -0.75em;
}

.ui.nine.cards > .card {
    width: calc(11.11111111% - 1.5em);
    margin: 0.75em;
}

.ui.cards.appIcons .card:hover {
    box-shadow: 0px 0px 15px 4px #d4d4d5, 0px 0px 0px 0px #d4d4d5 !important;
}

.ui.cards.appIcons ~ .ui.cards.appIcons {
    margin-top: -0.875em !important;
}

/*-----------------------------------------------------------sidebar------------------------------------------------*/
.containerMain {
    padding: 20px 20px 0;
    overflow: auto;
    height: calc(100% - 60px);
}

.cdp-udr .ui.segment.pushable {
    float: left;
    width: 100%;
    height: 100%;
    /*padding-top: 60px !important;*/
    z-index: 1;
}

.logo-ortho {
    width: 200px;
    padding: 38px 0;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-right: 10px;
}

.ui.left.sidebar,
.ui.right.sidebar {
    -webkit-box-shadow: 0px 0px 20px rgba(34, 36, 38, 0.15);
    box-shadow: 0px 0px 20px rgba(34, 36, 38, 0.15);
}

.cdp-udr .ui.visible.thin.left.sidebar ~ .fixed,
.cdp-udr .ui.visible.thin.left.sidebar ~ .pusher {
    transform: translate3d(300px, 0px, 0px);
}

.cdp-udr .ui.thin.left.sidebar ~ .fixed,
.cdp-udr .ui.thin.left.sidebar ~ .pusher {
    transform: translate3d(66px, 0px, 0px);
}

.cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment {
    padding-right: 300px;
    transition: all 0.66s ease 0s;
    -webkit-transition: all 0.66s ease 0s;
}

.cdp-udr .ui.left.push.sidebar {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
}

.cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .btnFeedback {
    right: 300px;
}

.cdp-udr .ui.visible.push.sidebar {
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment .btnFeedback {
    right: 300px;
}

.cdp-udr .ui.segment {
    border-radius: 0;
    border: none;
    box-shadow: none;
    padding: 0 66px 0 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

.cdp-udr .ui.thin.left.sidebar.visible {
    width: 300px;
    background: #d78831;
    background-image: linear-gradient(#d89336, #ca5b2d);
}


.cdp-udr .ui.thin.left.sidebar {
    width: 66px;
    background: #d78831;
    overflow-x: hidden;
}

.cdp-udr .ui.thin.left.sidebar::-webkit-scrollbar {
    display: none;
}

.cdp-udr .ui.thin.left.sidebar.visible .logo {
    width: 100%;
    padding: 18px;
}

.cdp-udr .ui.thin.left.sidebar .logo {
    width: 188px;
    padding: 5px;
}

.cdp-udr .ui.thin.left.sidebar .logoHolder {
    margin: 12px 5px;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu {
    background: none;
    width: 100%;
    border: none;
    box-shadow: none;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item {
    font-family: 'SegoeUI-Regular' !important;
    font-size: 14px;
    color: #262626;
    text-transform: uppercase;
    text-align: left;
    padding: 20px;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item .item {
    text-transform: capitalize;
    border-left: 1px solid #E6B390;
    margin-left: 5px;
    padding: 15px 20px;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item .item.active {
    background-color: #e6b288;
    border-left: 1px solid #D06F2A;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item {
    font-family: 'SegoeUI-Regular' !important;
    font-size: 0;
    color: #262626;
    text-transform: uppercase;
    text-align: right;
    padding: 20px;
}

.cdp-udr .ui.thin.left.sidebar.visible .vertical.menu .item i.icon {
    font-size: 15px !important;
    padding-right: 15px;
    display: inline;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item i.icon {
    font-size: 15px !important;
    padding-right: 10px;
    display: inline;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item i.icon.dropdown {
    padding-right: 0;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item .title {
    display: inline !important;
}

.cdp-udr .ui.thin.left.sidebar .vertical.menu .item .label {
    height: 22px;
    background: #d67d38;
    padding: 5px 7px;
    border-radius: 11px;
    min-width: 22px;
    margin-top: -5px;
}

.cdp-udr .pushable > .pusher {
    overflow: auto;
    background: #f0f0f0;
}

.cdp-udr .leftMenu.ui.accordion,
.cdp-udr .leftMenu.ui.accordion .content {
    float: left;
    padding: 0 !important;
    width: 100%;
}

.cdp-udr .leftMenu.ui.accordion > .title {
    float: left;
    width: 100%;
    padding: 0;
    background: rgba(0, 0, 0, 0.06);
    position: relative;
    margin-top: 1px;
}

.cdp-udr .leftMenu.ui.accordion > .title.active:before {
    content: " ";
    position: absolute;
    width: 5px;
    height: 50px;
    background: #fff;
    border-radius: 0 6px 6px 0;
    top: 0;
    left: 0;
}

.cdp-udr .leftMenu.ui.accordion > .title a,
.cdp-udr .leftMenu.ui.accordion > .title span {
    float: left;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
}

.cdp-udr .ui.menu:not(.visible) .leftMenu.ui.accordion > .title a {
    font-size: 0;
    text-align: center;
    padding: 14px 15px;
}


.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title a,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title span {
    padding: 16px 15px;
}

.cdp-udr .leftMenu.ui.accordion > .title span {
    font-size: 0;
    text-align: left;
    padding: 18px 5px 7px 22px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title a,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title span {
    font-size: 14px;
    text-align: left;
}

.cdp-udr .leftMenu.ui.accordion > .title a:hover,
.cdp-udr .leftMenu.ui.accordion > .title span:hover {
    text-decoration: none;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title a:hover,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title span:hover {
    padding-left: 20px;
    /*background: rgba(0, 0, 0, 0.06);*/
}

.cdp-udr .leftMenu.ui.accordion > .title a.active a,
.cdp-udr .leftMenu.ui.accordion > .title.active span {
    color: #fff;
}

.cdp-udr .leftMenu.ui.accordion > .title.active span i.icon.angle.down {
    transform: rotate(180deg);
    margin-top: 4px !important;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title.active span {
    padding-left: 20px;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title.active span:hover,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title a.active:hover {
    padding-left: 20px;
}

.cdp-udr .leftMenu.ui.accordion > .title a > .icon,
.cdp-udr .leftMenu.ui.accordion > .title .icon {
    margin-right: 0;
    font-size: 15px;
    color: #fff !important;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title a > .icon,
.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title .icon {
    margin: 2px 15px 0 0;
    font-size: 15px;
    float: left;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title .icon.angle {
    font-size: 14px;
    margin-top: 0;
    float: right;
    margin-right: 0 !important;
    transition: all 0.3s ease-in-out !important;
}

.cdp-udr .leftMenu.ui.accordion > .title .icon.angle.down {
    font-size: 12px;
    /*margin-top: 4px;*/
    float: right;
    margin-right: 0 !important;
    transition: all 0.3s ease-in-out !important;
}

.cdp-udr .leftMenu.ui.accordion > .title > .icon.dropdown {
    display: none;
}

.cdp-udr .leftMenu.ui.accordion > .title.active {
    background: rgb(0, 0, 0, 0.12);
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list {
    float: left;
    width: 100%;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list {
    padding-left: 0;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item {
    text-transform: capitalize;
    text-align: left;
    float: left;
    width: 100%;
    font-size: 0;
    background: rgba(0, 0, 0, 0.12);
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item:hover {
    float: left;
    width: 100%;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item:not(active):hover {
    background: rgba(0, 0, 0, 0.16);
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item {
    font-size: 14px;
    padding: 0 !important;
}

.cdp-udr .ui.sidebar .leftMenu.ui.accordion > .content .ui.list .item a {
    margin-left: 0;
    float: left;
    padding: 15px;
    width: 100%;
    border-left: none !important;
    text-align: center;
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item a {
    margin-left: 22px;
    float: left;
    padding: 12px 15px;
    width: calc(100% - 22px);
    text-align: left;
    border-left: 1px solid #d6a362 !important;
}


.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item > .icon {
    margin-right: 10px;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item:after {
    display: none;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item.active {
    background: rgba(0, 0, 0, 0.2);
    float: left;
    width: 100%;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item.active:hover {
    background: rgba(0, 0, 0, 0.2);
}

.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item.active a {
    border-left: 1px solid #fff !important;
}

.cdp-udr .leftMenu.ui.accordion > .content .ui.list .item .icon {
    font-size: 15px !important;
    color: #fff !important;
    line-height: 18px;
    margin: 0 auto !important;
    padding-left: 0;
    float: none;
}


.cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .content .ui.list .item .icon {
    line-height: normal;
    margin: 2px 10px 0 0 !important;
    float: left;

}

.float-leftX {
    padding-bottom: 10px;
}


/*----------------------------------------------------------Card--------------------------------------------------*/
.ui.card {
    width: 100%;
    box-shadow: none !important;
    margin-bottom: 20px !important;
    margin-top: 0 !important;
    border-radius: 4px;
}

.ui.card .content {
    padding: 15px;
    border-top: 1px solid #e1e1e1;
}

.ui.card .contentt {
    padding: 15px;
}

.ui.card .content .header {
    font-family: 'BentonSans-Bold';
    font-size: 18px !important;
    color: #273140;
    font-weight: normal !important;
}

.ui.card.mb0 {
    margin-bottom: 0 !important;
}

.ui.cards > .card {
    box-shadow: none !important;
}

/*------------------------------FreezeTable----------------------*/
.apiListTable {
    table-layout: fixed;
    width: 100%
}

.apiListTable td {
    word-wrap: break-word;
    white-space: break-spaces;
}

.FreezeTable {
    max-width: 100%;
    max-height: 70vh;
    overflow: auto;
    position: relative;
}

.FreezeTable table {
    position: relative;
    border-collapse: collapse;
}

.FreezeTable thead th {
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    top: 0;
    z-index: 1;
}

/*.FreezeTable tbody td:first-child{*/
/*    z-index: 2;*/
/*}*/
/*.FreezeTable thead th:first-child{*/
/*    z-index: 3 !important;*/
/*}*/
/*.FreezeTable tbody td:first-child.bgLightGray{*/
/*    z-index: 3;*/
/*}*/
/*.FreezeTable tbody td:first-child,*/
/*.FreezeTable thead th:first-child{*/
/*    left: 0 !important;*/
/*    position: -webkit-sticky; !* for Safari *!*/
/*    position: sticky;*/
/*}*/

.FreezeTable tbody td.bgLightGray {
    top: 43px;
    left: 0;
    position: -webkit-sticky;
    /* for Safari */
    position: sticky;
    z-index: 1;
}

.icon.iconDentist {
    background: url("../images/iconDentistOutline.png") no-repeat;
}

.ui.pagination.menu {
    float: right;
    margin-top: 15px;
}

/*----------------------------------------------Sticky-Table-Header--------------------------------------------*/
.sticky-header thead th {
    position: sticky;
    top: 0;
    z-index: 3;
    padding: 5px 0.1rem !important;
    font-size: 12px;
}

.sticky-header .ui.selection.dropdown {
    min-width: 10em;
}

.center {
    text-align: center;
}

.sticky-header td {
    padding: 5px 0.1rem !important;
}

.sticky-header {
    display: block;
    height: 65vh;
    overflow: auto;
}

.sticky-header table {
    border-collapse: collapse;
}

/*-------------------------------------------------Text-Color------------------------------------------------------*/

.text-red {
    color: #f31e05 !important;
}

.text-green {
    color: #68c802 !important;
}

/*----------------------------------------------Patient-Care-Report--------------------------------------------*/
.spotLightRed {
    background: url("../images/spotLightRed.png") no-repeat;
    background-position: right 5px center;
    padding-right: 30px !important;
}

.spotLightGreen {
    background: url("../images/spotLightGreen.png") no-repeat;
    background-position: right 5px center;
    padding-right: 30px !important;
}

.spotLightYellow {
    background: url("../images/spotLightYellow.png") no-repeat;
    background-position: right 5px center;
    padding-right: 30px !important;
}

/*-----------------------------------------Table-------------------------------------------------*/
.table-responsive {
    overflow: auto;
}

.ui.table.tableStyle {
    background: #DDDDDD;
    border: none;
}

.ui.table.tableStyle thead th {
    font-family: 'SegoeUI-Semibold';
    border-top: none;
    color: #000 !important;
    background: #EECEAF !important;
    border-radius: 0 !important;
    border-bottom: 3px solid #EECEAF !important;
    vertical-align: middle !important;
    padding: 2px 0.75rem !important;
    min-width: 100px;
}

.ui.table.tableStyle thead th > div {
    min-height: 47px;
    align-items: center;
    display: flex;
    align-content: center;
}

.ui.table.tableStyle thead th.active {
    border-bottom: 3px solid #ce6429 !important;
}

.ui.table.tableStyle thead i.icon {
    color: #ce6429;
    opacity: 0.6;
    margin-left: 15px;
}

.ui.table.tableStyle thead th.active i.icon {
    opacity: 1;
}

.ui.table.tableStyle thead th:hover i.icon {
    color: #ce6429;
    opacity: 1;
}

.table.tableStyle.by-practice-os th {
    width: 30% !important;
}

.tableWithLight .ui.table.tableStyle tr td + td,
.tableWithLight .ui.table.tableStyle tr th + th {
    text-align: right;
    padding-right: 30px !important;
}

.tableWithLight .ui.table.tableStyle thead th > div {
    display: grid;
}

.tableWithLight .table thead th {
    min-width: auto;
}

.table.by-practice th {
    width: 20%;
}

.ui.table.tableStyle td,
.ui.table.tableStyle th {
    padding: 5px;
}

.ui.table.tableStyle tr td:first-child {
    background: #ffa905;
    font-family: 'SegoeUI-Semibold';
    font-weight: normal;
}

.ui.table.tableStyle tr td:last-child {
    font-weight: bold;
}

.table.by-splashboard.table1 tbody tr:nth-child(5) td,
.table.by-splashboard.table1 tbody tr:nth-child(6) td,
.table.by-age.table1 tbody tr:nth-child(6) td,
.table.by-age.table1 tbody tr:nth-child(7) td {
    background: #E5F2FF !important;
}

.table.by-splashboard.table1 tbody tr:nth-child(7) td {
    background: #DBEFDA !important;
}

.table.by-splashboard.table1 tbody tr:nth-child(8) td,
.table.by-splashboard.table1 tbody tr:nth-child(9) td,
.table.by-splashboard.table1 tbody tr:nth-child(10) td {
    background: #F7E8D7 !important;
}

.table.by-practice.table1 tbody tr:nth-child(5) td,
.table.by-practice.table1 tbody tr:nth-child(6) td {
    background: #E5F2FF !important;
}

.header-os {
    width: 10% !important;
}

.header-os-inner {
    width: 15% !important;
}

.ui.table.tableStyle.CSR tr td {
    font-size: 16px;
}

.ui.table.tableStyle.CSR tr td + td {
    font-size: 18px;
}

.table-adjAcknowledgement h5 {
    display: none;
}

.ui.table.tableStyle tr td.adjNotes {
    /*word-break: break-word;*/
    white-space: normal;
    /*width: 500px;*/
}

.ui.table.tableStyle tr th.adjNotesHead div {
    width: 300px;
}

.ui.table .icon.edit:hover {
    color: #666 !important;
    cursor: pointer;
}

.ui.table .icon.trash:hover {
    color: #DB2828 !important;
    cursor: pointer;
}

.ui.table.tableStyle.tableOpenSurgery .first-th {
    width: 250px !important;
    max-width: 250px !important;
}

.center-toggle-element {
    display: flex;
    align-items: center;
}

/*------------------------------------------------------chartjs--------------------------------------*/
.chartjs-render-monitor {
    position: relative;
    bottom: -6px;
}

/**************************************Acknowlegement Confirmation page *******************************/
.acknowlegeConfirm {
    text-align: center;
    margin-top: 100px;
}

.acknowlegeConfirm i.checkmark.huge.icon {
    color: #006400 !important;
}

.linkReadMore {
    display: block;
    font-size: 12px;
    color: #F2711C;
    line-height: 8px;
}

/*------------------------------------------------------Feedback-btn------------------------------------------------*/
.btnFeedback {
    width: 30px;
    height: 100px !important;
    background-color: #d16f26;
    border-radius: 10px 0 0 10px;
    z-index: 999;
    box-shadow: 0px 0px 15px rgba(68, 68, 68, 0.4) !important;
    text-align: center;
    color: #222;
    padding: 18px 0;
    position: fixed;
    right: 66px;
    cursor: pointer;
    top: 50%;
}

.btnFeedback .icon {
    font-size: 20px !important;
    color: #fff !important;
    margin-right: 0 !important;
}

.btnFeedback small {
    font-size: 13px;
    color: #fff;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    letter-spacing: 0.5px;
}

.btnFeedback:hover {
    width: 40px;
    background-color: #c4631b;
}

.primaryUnderline {
    color: #d16f26;
    text-decoration: underline;
    cursor: pointer;
}

/*-----------------------------------------Extra----------------------------------------*/
.search-bar .list-group {
    margin: 0;
}

.fourCoulmns > div {
    width: 370px;
    border-radius: 4px;
    background: #fff;
    margin: 0 20px 20px 0;
    padding: 15px;
}

.ui.search .prompt {
    border-radius: 4px !important;
}

/*-----------------------------------------------------------Responsive------------------------------------------------*/

@media (max-width: 1400px) {
    .login .ui.grid > .row > [class*="fourteen wide computer"].column.customGrid {
        width: 70% !important;
    }

    .loginLogo {
        padding-top: 80px;
    }

    .login button.google {
        margin: 30px 0 50px;
    }

    .ui.table.tableStyle.tableOpenSurgery .first-th {
        width: 170px !important;
        max-width: 170px !important;
    }
}


@media (max-width: 1199px) {

    .cdp-udr .ui.visible.thin.left.sidebar ~ .fixed,
    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher {
        transform: none;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment {
        padding-right: 0;
    }

    .cdp-udr .ui.thin.left.sidebar ~ .pusher .ui.secondary.menu .item.toggle {
        margin-left: 0 !important;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.secondary.menu .item.toggle {
        margin-left: 300px !important;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment .btnFeedback {
        right: 0;
    }
}


@media (max-width: 991px) {
    .login .bgShape {
        background: url("../images/bgShapeMobile.png") no-repeat;
        background-size: contain;
    }

    .login .ui.grid > .row > [class*="fourteen wide computer"].column.customGrid {
        width: 100% !important;
    }

    .loginLogo {
        padding-top: 160px;
    }

    .ui.pointing.secondary.menu .item {
        padding: 20px 30px !important;
    }

    .landing {
        background-position: center bottom;
        background-size: contain;
    }

    .landing > .ui.grid {
        margin-top: 30px;
    }
}

@media (max-width: 768px) {
    .loginLogo {
        padding-top: 150px;
    }

    .practiceSlider {
        width: calc(100% - 60px);
    }

    .practiceSlider .practiceImage {
        width: 100%;
        margin-bottom: 15px;
        height: 100%;
        max-height: 500px;
    }

    .practiceSlider .next-btn,
    .practiceSlider .prev-btn {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #fff;
        padding: 15px 2px;
    }

    .practiceSlider .prev-btn {
        left: 10px;
    }

    .practiceSlider .next-btn {
        right: 10px;
    }
}

@media (max-width: 767px) {
    .landing {
        background-position: 50% 120%;
        background-size: contain;
    }


    .cdp-udr .ui.thin.left.sidebar ~ .fixed,
    .cdp-udr .ui.thin.left.sidebar ~ .pusher {
        transform: translate3d(0, 0px, 0px);
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .fixed,
    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher {
        /*transform: translate3d(300px, 0px, 0px);*/
        transform: none;
    }

    .cdp-udr .ui.segment {
        padding: 0;
    }

    .cdp-udr .ui.thin.left.sidebar {
        width: 0;
    }

    .cdp-udr .ui.secondary.menu .item.toggle {
        position: absolute;
        z-index: 1;
    }


    .cdp-udr .ui.secondary.menu .logoSmall {
        display: block;
        margin: 8px 0 8px 62px;
        float: left;
    }

    .cdp-udr .ui.secondary.menu .item.toggle:hover {
        background: #fff;
    }

    .cdp-udr .ui.thin.left.sidebar.visible .logo {
        width: 180px;
        padding: 15px 0;
    }

    .cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title a > .icon,
    .cdp-udr .ui.sidebar.visible .leftMenu.ui.accordion > .title .icon {
        font-size: 14px;
    }

    .ui.table.tableStyle.CSR tr td,
    .ui.table.tableStyle.CSR tr td + td {
        font-size: 14px;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .btnFeedback,
    .btnFeedback {
        right: 0 !important;
    }

    .cdp-udr .ui.visible.thin.left.sidebar ~ .pusher .btnFeedback,
    .btnFeedback {
        right: 0;
    }

    .topFilters > div,
    .topFilters button {
        margin-bottom: 10px !important;
    }

    .dataReports > div {
        width: 100%;
        margin-right: 0;
    }
}


@media (max-width: 640px) {
    .loginLogo {
        width: calc(100% - 40px);
    }

    .practiceSlider {
        width: calc(100% - 30px);
    }

    .practiceSlider .practiceImage {
        max-height: 400px;
    }

    .practiceSlider .next-btn,
    .practiceSlider .prev-btn {
        top: 40%;
    }

    .practiceSlider .prev-btn {
        left: 10px;
    }

    .practiceSlider .next-btn {
        right: 10px;
    }


}

@media (max-width: 575px) {
    .header-os {
        width: 5% !important;
    }

    .header-os-inner {
        width: 10% !important;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    .landing {
        background-position: center bottom;
    }

    .topFilterz {
        width: 100%;
        text-align: left;
    }

    .topFilterz .ui.dropdown,
    .topFilterz .ui.input {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .label {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        background-color: white;
    }

    .topFilterz .ui.button {

        margin-right: 0 !important;
        width: 20px;
    }


    .topFilterz div.left {
        text-align: justify;
    }

    .datePicker .react-datepicker-wrapper {
        width: 100%;
    }

    .topFilters {
        display: block;
        width: 100%;
    }

    .topFilters .filter {
        text-align: left;
    }

    .topFilters .ui.dropdown,
    .topFilters .ui.input,
    .topFilters .filter,
    .topFilters .datePicker,
    .topFilters .datePicker input {
        width: 100%;
        margin-left: 0 !important;
    }

    .topFilters .ui.button {
        float: right;
        margin-right: 0 !important;
        margin-left: 10px;
    }

    .topFilters div.float-right {
        text-align: right;
    }
}

@media (max-width: 420px) {
    .cdp-udr .ui.secondary.menu .right.menu .item {
        padding: 21px 10px;
    }

    .cdp-udr .ui.secondary.menu .dropdown.user {
        padding: 17px 0px;
    }

    .cdp-udr .ui.secondary.menu .dropdown.user .text {
        margin-top: 4px;
    }

}


.react-datepicker__close-icon {
    padding: 0px 14px 14px 0px !important;
    z-index: 1 !important;
}

.react-datepicker-popper {
    z-index: 9;
}

.by-budget-by-work-day td:first-child,
.by-provider-cpv td:first-child {
    text-transform: capitalize;
}

.t-header {
    color: #d16f26 !important;
    min-width: '62px' !important;
}

.error {
    color: red;
}

/* Practice Manage Dashbard */
.column-left {
    float: left;
    width: 33%;

}

.column-right {
    float: right;
    width: 33%;

}

.column-center {
    display: inline-block;
    width: 33%;

}

h1 {
    color: rgb(63, 81, 181);
    font-weight: bold;
}

.buttons-right > button {
    float: right;
    margin: 5px;
}

.app-container {
    padding: 25px;
}

.percentage-number {
    color: rgb(63, 81, 181);
    font-size: 50px;
    font-weight: bold;
}

.percentage-sign {
    color: rgb(63, 81, 181);
    font-size: 40px;
}

.percentage-container {
    text-align: center;
}

.percentage-container:first-child {
    padding-bottom: 3em;
}

/* search bar css start here */
.search-bar-dropdown {
    width: 30em;
}

#results {
    position: absolute;
    width: inherit;
    overflow-y: scroll;
    height: 20em;
    /* border-bottom: 1px #eeeeee solid; */
}

#results > button {
    background-color: #fafafa;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: #eeeeee;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#search-bar {
    width: inherit;
}

/* search bar css end here */
.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

i.sort.icon.valSort:hover {
    background-color: #ffffff;
}

i.sort.icon.valSort {
    cursor: pointer;
}

.favourite {
    margin-left: 0 !important;
}

.star-grey {
    color: #808080 !important;
    font-size: 22px;
}

.star-not-grey {
    color: #d16f26 !important;
    font-size: 22px
}


/*-------------------------------------------------------------------Table-----------------------------------------*/

.table {
    border-radius: 0;
    border: none;
    font-size: 13px;
    width: 100%;
}

.table td {
    padding: 5px 0.5rem;
}

.table thead th {
    border-top: none;
    color: #000 !important;
    background: #EECEAF !important;
    border-radius: 0 !important;
    border-bottom: 3px solid #EECEAF !important;
    vertical-align: middle !important;
    padding: 2px 0.75rem !important;
    min-width: 100px;
}

.table thead th > div {
    min-height: 47px;
    align-items: center;
    display: flex;
    align-content: center;
}

.table thead th.active {
    border-bottom: 3px solid #ce6429 !important;
}

.table thead i.icon {
    color: #ce6429;
    margin-left: 10px;
}

.table thead th.active i.icon {
    opacity: 1;
}

.table thead th:hover i.icon {
    color: #ce6429;
    opacity: 1;
}

.table tbody tr:hover {
    background: #fafcfd;
    color: #000;
}

.table .ui.button {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #fff;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #FCF5EF !important;
}

.table-striped tbody tr:nth-of-type(odd):hover {
    background-color: #f9eadd !important;
}

.table.no-wrap tr th {
    white-space: nowrap;
}

/*PMD CSS CDP-3133*/

.mediaViewInfo {
    --web-view-name: Web 1920 – 1;
    --web-view-id: Web_1920__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

:root {
    --web-view-ids: Web_1920__1;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#Web_1920__1 {
    position: absolute;
    width: 1920px;
    height: 1554px;
    background-color: rgba(236, 238, 241, 1);
    overflow: hidden;
    --web-view-name: Web 1920 – 1;
    --web-view-id: Web_1920__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#Rectangle_93 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_93 {
    filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.059));
    position: absolute;
    overflow: visible;
    width: 624px;
    height: 314px;
    left: 110px;
    top: 215px;
}

#Rectangle_96 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_96 {
    filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.059));
    position: absolute;
    overflow: visible;
    width: 624px;
    height: 222px;
    left: 110px;
    top: 557px;
}

#Rectangle_94 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_94 {
    filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.059));
    position: absolute;
    overflow: visible;
    width: 623px;
    height: 314px;
    left: 716px;
    top: 215px;
}

#Rectangle_97 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_97 {
    filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.059));
    position: absolute;
    overflow: visible;
    width: 623px;
    height: 311px;
    left: 716px;
    top: 509px;
}

#Rectangle_95 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_95 {
    filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.059));
    position: absolute;
    overflow: visible;
    width: 624px;
    height: 272px;
    left: 1321px;
    top: 215px;
}

#Rectangle_98 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_98 {
    filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.059));
    position: absolute;
    overflow: visible;
    width: 624px;
    height: 299px;
    left: 1321px;
    top: 467px;
}

#Rectangle_84 {
    fill: rgba(255, 246, 237, 1);
}

.Rectangle_84 {
    position: absolute;
    overflow: visible;
    width: 576px;
    height: 49px;
    left: 110px;
    top: 215px;
}

#Rectangle_105 {
    fill: rgba(255, 246, 237, 1);
}

.Rectangle_105 {
    position: absolute;
    overflow: visible;
    width: 576px;
    height: 49px;
    left: 110px;
    top: 557px;
}

#Rectangle_103 {
    fill: rgba(255, 246, 237, 1);
}

.Rectangle_103 {
    position: absolute;
    overflow: visible;
    width: 576px;
    height: 49px;
    left: 716px;
    top: 215px;
}

#Rectangle_106 {
    fill: rgba(255, 246, 237, 1);
}

.Rectangle_106 {
    position: absolute;
    overflow: visible;
    width: 576px;
    height: 49px;
    left: 716px;
    top: 509px;
}

#Rectangle_104 {
    fill: rgba(255, 246, 237, 1);
}

.Rectangle_104 {
    position: absolute;
    overflow: visible;
    width: 576px;
    height: 49px;
    left: 1321px;
    top: 215px;
}

#Rectangle_107 {
    fill: rgba(255, 246, 237, 1);
}

.Rectangle_107 {
    position: absolute;
    overflow: visible;
    width: 576px;
    height: 49px;
    left: 1321px;
    top: 467px;
}

#Production {
    left: 166px;
    top: 227px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
    text-transform: uppercase;
}

#Group_15 {
    position: absolute;
    width: 23px;
    height: 21px;
    left: 133px;
    top: 231px;
    overflow: visible;
}

#Group_13 {
    position: absolute;
    width: 15px;
    height: 10px;
    left: 4px;
    top: 3px;
    overflow: visible;
}

#Path_84 {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_84 {
    overflow: visible;
    position: absolute;
    width: 17.792px;
    height: 12.792px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_14 {
    position: absolute;
    width: 23px;
    height: 21px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Line_4 {
    fill: transparent;
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Line_4 {
    overflow: visible;
    position: absolute;
    width: 25px;
    height: 2px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Rectangle_86 {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_86 {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 18px;
    left: 1px;
    top: 0px;
}

#Line_5 {
    fill: transparent;
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Line_5 {
    overflow: visible;
    position: absolute;
    width: 6.811px;
    height: 7.811px;
    left: 6px;
    top: 16px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Line_6 {
    fill: transparent;
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Line_6 {
    overflow: visible;
    position: absolute;
    width: 6.811px;
    height: 7.811px;
    left: 13px;
    top: 16px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#SCHEDULE {
    left: 767px;
    top: 228px;
    position: absolute;
    overflow: visible;
    width: 88px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
    text-transform: uppercase;
}

#Group_18 {
    position: absolute;
    width: 59px;
    height: 27px;
    left: 1210px;
    top: 226px;
    overflow: visible;
}

#Rectangle_75 {
    fill: rgba(215, 150, 50, 1);
}

.Rectangle_75 {
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 27px;
    left: 0px;
    top: 0px;
}

#TODAY {
    left: 9px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-transform: lowercase;
}

#Group_17 {
    position: absolute;
    width: 18px;
    height: 24px;
    left: 739px;
    top: 228px;
    overflow: visible;
}

#Path_85 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_85 {
    overflow: visible;
    position: absolute;
    width: 10.4px;
    height: 4.4px;
    left: 4px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_86 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_86 {
    overflow: visible;
    position: absolute;
    width: 18.4px;
    height: 22.4px;
    left: 0px;
    top: 2px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_87 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_87 {
    overflow: visible;
    position: absolute;
    width: 7.4px;
    height: 1.4px;
    left: 8px;
    top: 8px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_88 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_88 {
    overflow: visible;
    position: absolute;
    width: 3.9px;
    height: 1.4px;
    left: 3px;
    top: 8px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_89 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_89 {
    overflow: visible;
    position: absolute;
    width: 7.4px;
    height: 1.4px;
    left: 8px;
    top: 11.667px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_90 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_90 {
    overflow: visible;
    position: absolute;
    width: 3.9px;
    height: 1.4px;
    left: 3px;
    top: 11.667px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_91 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_91 {
    overflow: visible;
    position: absolute;
    width: 7.4px;
    height: 1.4px;
    left: 8px;
    top: 15.333px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_92 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_92 {
    overflow: visible;
    position: absolute;
    width: 3.9px;
    height: 1.4px;
    left: 3px;
    top: 15.333px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_93 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_93 {
    overflow: visible;
    position: absolute;
    width: 7.4px;
    height: 1.4px;
    left: 8px;
    top: 19px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_94 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.4000000059604645px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_94 {
    overflow: visible;
    position: absolute;
    width: 3.9px;
    height: 1.4px;
    left: 3px;
    top: 19px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#BILLING {
    left: 1376px;
    top: 228px;
    position: absolute;
    overflow: visible;
    width: 66px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
    text-transform: uppercase;
}

#Group_21 {
    position: absolute;
    width: 24px;
    height: 16px;
    left: 1342px;
    top: 232px;
    overflow: visible;
}

#Path_95 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_95 {
    overflow: visible;
    position: absolute;
    width: 24.3px;
    height: 16.3px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_96 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_96 {
    overflow: visible;
    position: absolute;
    width: 5.3px;
    height: 1.3px;
    left: 2px;
    top: 11px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_97 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_97 {
    overflow: visible;
    position: absolute;
    width: 4.3px;
    height: 1.3px;
    left: 9px;
    top: 11px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_20 {
    position: absolute;
    width: 67px;
    height: 27px;
    left: 1808px;
    top: 226px;
    overflow: visible;
}

#Rectangle_75_bm {
    fill: rgba(215, 150, 50, 1);
}

.Rectangle_75_bm {
    position: absolute;
    overflow: visible;
    width: 67px;
    height: 27px;
    left: 0px;
    top: 0px;
}

#ALL_TIME {
    left: 7px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-transform: lowercase;
}

#MCO_LIST {
    left: 160px;
    top: 570px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
    text-transform: uppercase;
}

#Group_22 {
    position: absolute;
    width: 67px;
    height: 27px;
    left: 597px;
    top: 568px;
    overflow: visible;
}

#Rectangle_75_bq {
    fill: rgba(215, 150, 50, 1);
}

.Rectangle_75_bq {
    position: absolute;
    overflow: visible;
    width: 67px;
    height: 27px;
    left: 0px;
    top: 0px;
}

#ID30_DAYS {
    left: 7px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-transform: lowercase;
}

#Group_38 {
    position: absolute;
    width: 119px;
    height: 27px;
    left: 545px;
    top: 227px;
    overflow: visible;
}

#Rectangle_75_bt {
    fill: rgba(215, 150, 50, 1);
}

.Rectangle_75_bt {
    position: absolute;
    overflow: visible;
    width: 119px;
    height: 27px;
    left: 0px;
    top: 0px;
}

#May_19_2021 {
    left: 12px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
}

#Group_27 {
    position: absolute;
    width: 18px;
    height: 24px;
    left: 132px;
    top: 570px;
    overflow: visible;
}

#Path_98 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_98 {
    overflow: visible;
    position: absolute;
    width: 18.3px;
    height: 22.3px;
    left: 0px;
    top: 2px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_99 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_99 {
    overflow: visible;
    position: absolute;
    width: 10.3px;
    height: 5.3px;
    left: 4px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_100 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_100 {
    overflow: visible;
    position: absolute;
    width: 10.3px;
    height: 1.3px;
    left: 4px;
    top: 9px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_101 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_101 {
    overflow: visible;
    position: absolute;
    width: 10.3px;
    height: 1.3px;
    left: 4px;
    top: 14px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Schedule_coordinator {
    left: 772px;
    top: 522px;
    position: absolute;
    overflow: visible;
    width: 219px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
    text-transform: uppercase;
}

#Group_28 {
    position: absolute;
    width: 67px;
    height: 27px;
    left: 1202px;
    top: 520px;
    overflow: visible;
}

#Rectangle_75_b {
    fill: rgba(215, 150, 50, 1);
}

.Rectangle_75_b {
    position: absolute;
    overflow: visible;
    width: 67px;
    height: 27px;
    left: 0px;
    top: 0px;
}

#today {
    left: 12px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    text-transform: lowercase;
}

#Community_outreach {
    left: 1381px;
    top: 480px;
    position: absolute;
    overflow: visible;
    width: 209px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
    text-transform: uppercase;
}

#Group_31 {
    position: absolute;
    width: 28.869px;
    height: 18.551px;
    left: 1343px;
    top: 482px;
    overflow: visible;
}

#Path_102 {
    fill: rgba(22, 53, 103, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_102 {
    overflow: visible;
    position: absolute;
    width: 6.793px;
    height: 8.648px;
    left: 11.045px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_103 {
    fill: rgba(22, 53, 103, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_103 {
    overflow: visible;
    position: absolute;
    width: 5.865px;
    height: 5.865px;
    left: 4.088px;
    top: 0.464px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_104 {
    fill: rgba(22, 53, 103, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_104 {
    overflow: visible;
    position: absolute;
    width: 10.007px;
    height: 6.793px;
    left: 0px;
    top: 9.275px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_105 {
    fill: rgba(22, 53, 103, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_105 {
    overflow: visible;
    position: absolute;
    width: 5.865px;
    height: 5.865px;
    left: 19.216px;
    top: 0.464px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_106 {
    fill: rgba(22, 53, 103, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_106 {
    overflow: visible;
    position: absolute;
    width: 9.974px;
    height: 6.793px;
    left: 19.199px;
    top: 9.275px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_107 {
    fill: rgba(22, 53, 103, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_107 {
    overflow: visible;
    position: absolute;
    width: 15.942px;
    height: 8.648px;
    left: 6.472px;
    top: 10.203px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_32 {
    position: absolute;
    width: 22px;
    height: 24px;
    left: 740px;
    top: 522px;
    overflow: visible;
}

#Group_32_cd {
    position: absolute;
    width: 22px;
    height: 9px;
    left: 0px;
    top: 15px;
    overflow: visible;
}

#Path_108 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_108 {
    overflow: visible;
    position: absolute;
    width: 22.302px;
    height: 9.3px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_33 {
    position: absolute;
    width: 9.816px;
    height: 12px;
    left: 5.592px;
    top: 0px;
    overflow: visible;
}

#Path_109 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.30000001192092896px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_109 {
    overflow: visible;
    position: absolute;
    width: 10.124px;
    height: 12.3px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Rectangle_113 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_113 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 270px;
    top: 295.5px;
}

#Rectangle_115 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_115 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 270px;
    top: 332.5px;
}

#ID16524 {
    left: 280px;
    top: 302.5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#Scheduled_Prod {
    left: 280px;
    top: 337.5px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Rectangle_123 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_123 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 132px;
    top: 381.5px;
}

#Rectangle_126 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_126 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 132px;
    top: 418.5px;
}

#ID42700 {
    left: 142px;
    top: 388.5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#Today_Prod {
    left: 142px;
    top: 423.5px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Rectangle_118 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_118 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 407px;
    top: 295.5px;
}

#Rectangle_117 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_117 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 407px;
    top: 332.5px;
}

#ID572700 {
    left: 417px;
    top: 302.5px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#LWD_Prod {
    left: 417px;
    top: 337.5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Rectangle_121 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_121 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 270px;
    top: 418.5px;
}

#LWD_PT_Count {
    left: 280px;
    top: 423.5px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Rectangle_120 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_120 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 545px;
    top: 295.5px;
}

#Rectangle_145 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_145 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 739px;
    top: 295.5px;
}

#Rectangle_150 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_150 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 739px;
    top: 381.5px;
}

#Rectangle_162 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_162 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 40px;
    left: 739px;
    top: 584.5px;
}

#Rectangle_167 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_167 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 40px;
    left: 739px;
    top: 670.5px;
}

#Rectangle_169 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_169 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 40px;
    left: 933.5px;
    top: 670.5px;
}

#Rectangle_165 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_165 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 40px;
    left: 934px;
    top: 584.5px;
}

#Rectangle_170 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_170 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 40px;
    left: 1342px;
    top: 542.5px;
}

#Rectangle_176 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_176 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 40px;
    left: 1342px;
    top: 628.5px;
}

#Rectangle_173 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_173 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 40px;
    left: 1540px;
    top: 542.5px;
}

#Rectangle_177 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_177 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 40px;
    left: 1540px;
    top: 628.5px;
}

#Rectangle_152 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_152 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 880px;
    top: 381.5px;
}

#Rectangle_148 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_148 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 881px;
    top: 295.5px;
}

#Rectangle_153 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_153 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 1342px;
    top: 295.5px;
}

#Rectangle_156 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_156 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 1484px;
    top: 295.5px;
}

#Rectangle_119 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_119 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 545px;
    top: 332.5px;
}

#Rectangle_146 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_146 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 739px;
    top: 332.5px;
}

#Rectangle_149 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_149 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 739px;
    top: 418.5px;
}

#Rectangle_163 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_163 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 28px;
    left: 739px;
    top: 621.5px;
}

#Rectangle_166 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_166 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 28px;
    left: 739px;
    top: 707.5px;
}

#Rectangle_168 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_168 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 28px;
    left: 933.5px;
    top: 707.5px;
}

#Rectangle_164 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_164 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 28px;
    left: 934px;
    top: 621.5px;
}

#Rectangle_171 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_171 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 28px;
    left: 1342px;
    top: 579.5px;
}

#Rectangle_174 {
    fill: rgba(53, 186, 155, 1);
}

.Rectangle_174 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 28px;
    left: 1342px;
    top: 665.5px;
}

#Rectangle_172 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_172 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 28px;
    left: 1540px;
    top: 579.5px;
}

#Rectangle_175 {
    fill: rgba(235, 84, 99, 1);
}

.Rectangle_175 {
    position: absolute;
    overflow: visible;
    width: 176.223px;
    height: 28px;
    left: 1540px;
    top: 665.5px;
}

#Rectangle_151 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_151 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 880px;
    top: 418.5px;
}

#Rectangle_147 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_147 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 881px;
    top: 332.5px;
}

#Rectangle_154 {
    fill: rgba(235, 84, 99, 1);
}

.Rectangle_154 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 1342px;
    top: 332.5px;
}

#Rectangle_155 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_155 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 1484px;
    top: 332.5px;
}

#ID30200 {
    left: 555px;
    top: 302.5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID118 {
    left: 749px;
    top: 302.5px;
    position: absolute;
    overflow: visible;
    width: 30px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID50 {
    left: 749px;
    top: 388.5px;
    position: absolute;
    overflow: visible;
    width: 20px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID13 {
    left: 749px;
    top: 591.5px;
    position: absolute;
    overflow: visible;
    width: 20px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID20 {
    left: 749px;
    top: 677.5px;
    position: absolute;
    overflow: visible;
    width: 20px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID108 {
    left: 943.5px;
    top: 677.5px;
    position: absolute;
    overflow: visible;
    width: 30px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID87 {
    left: 944px;
    top: 591.5px;
    position: absolute;
    overflow: visible;
    width: 20px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID5 {
    left: 1352px;
    top: 549.5px;
    position: absolute;
    overflow: visible;
    width: 11px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID0 {
    left: 1352px;
    top: 635.5px;
    position: absolute;
    overflow: visible;
    width: 11px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(235, 84, 99, 1);
}

#ID0_dy {
    left: 1550px;
    top: 549.5px;
    position: absolute;
    overflow: visible;
    width: 11px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(235, 84, 99, 1);
}

#ID3 {
    left: 1550px;
    top: 635.5px;
    position: absolute;
    overflow: visible;
    width: 11px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID49 {
    left: 890px;
    top: 388.5px;
    position: absolute;
    overflow: visible;
    width: 20px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID3_d {
    left: 891px;
    top: 302.5px;
    position: absolute;
    overflow: visible;
    width: 11px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID000 {
    left: 1352px;
    top: 302.5px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID0_d {
    left: 1494px;
    top: 302.5px;
    position: absolute;
    overflow: visible;
    width: 11px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ACT_CPV {
    left: 555px;
    top: 337.5px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Sch_Apt_Today {
    left: 749px;
    top: 337.5px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Expected_Show {
    left: 749px;
    top: 423.5px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#APT_Created_Next_4_Days {
    left: 749px;
    top: 626.5px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#APT_Created_Goal {
    left: 749px;
    top: 712.5px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#No_Future_Apt {
    left: 943.5px;
    top: 712.5px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#No_Future_wtc {
    left: 944px;
    top: 626.5px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Leads_Entered_Today {
    left: 1352px;
    top: 584.5px;
    position: absolute;
    overflow: visible;
    width: 127px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Leads_Won_Today {
    left: 1374px;
    top: 670.5px;
    position: absolute;
    overflow: visible;
    width: 109px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Leads_Worked_Today {
    left: 1550px;
    top: 584.5px;
    position: absolute;
    overflow: visible;
    width: 128px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Leads_Lost_Today {
    left: 1572px;
    top: 670.5px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#PT_Goal_Today {
    left: 890px;
    top: 423.5px;
    position: absolute;
    overflow: visible;
    width: 88px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#PT_Seen_Today {
    left: 891px;
    top: 337.5px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Not_Billed {
    left: 1352px;
    top: 337.5px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Not_Billed_Proc {
    left: 1494px;
    top: 337.5px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Rectangle_128 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_128 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 132px;
    top: 295.5px;
}

#Rectangle_158 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_158 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 132px;
    top: 632px;
}

#Rectangle_161 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_161 {
    position: absolute;
    overflow: visible;
    width: 139px;
    height: 40px;
    left: 270px;
    top: 632px;
}

#Rectangle_129 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_129 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 132px;
    top: 332.5px;
}

#Rectangle_159 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_159 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 132px;
    top: 669px;
}

#Rectangle_160 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_160 {
    position: absolute;
    overflow: visible;
    width: 139px;
    height: 28px;
    left: 270px;
    top: 669px;
}

#ID28515 {
    left: 142px;
    top: 302.5px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID138 {
    left: 142px;
    top: 639px;
    position: absolute;
    overflow: visible;
    width: 30px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#ID834 {
    left: 280px;
    top: 639px;
    position: absolute;
    overflow: visible;
    width: 30px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#BD_CPV {
    left: 142px;
    top: 337.5px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Rc_No_Future_Work {
    left: 280px;
    top: 674px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#NP_Work_Now {
    left: 141px;
    top: 674px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Rectangle_125 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_125 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 407px;
    top: 381.5px;
}

#Rectangle_122 {
    fill: rgba(236, 238, 241, 1);
}

.Rectangle_122 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 28px;
    left: 407px;
    top: 418.5px;
}

#ID1685 {
    left: 417px;
    top: 388.5px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#Delta_CPV {
    left: 417px;
    top: 423.5px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Rectangle_127 {
    fill: rgba(74, 136, 218, 1);
}

.Rectangle_127 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.071));
    position: absolute;
    overflow: visible;
    width: 235px;
    height: 74px;
    left: 110px;
    top: 480.5px;
}

#Component_5__1 {
    position: absolute;
    width: 126px;
    height: 21px;
    left: 167.848px;
    top: 497.5px;
    overflow: visible;
}

#View_Todays_Goal {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 127px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(224, 248, 255, 1);
    text-decoration: underline;
}

#Group_35 {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 135.848px;
    top: 497.5px;
    overflow: visible;
}

#Path_110 {
    fill: rgba(224, 248, 255, 1);
    stroke: rgba(224, 248, 255, 1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_110 {
    overflow: visible;
    position: absolute;
    width: 13.555px;
    height: 13.51px;
    left: 11px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_111 {
    fill: rgba(224, 248, 255, 1);
    stroke: rgba(224, 248, 255, 1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_111 {
    overflow: visible;
    position: absolute;
    width: 23.5px;
    height: 23.5px;
    left: 0px;
    top: 1px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_112 {
    fill: rgba(224, 248, 255, 1);
    stroke: rgba(224, 248, 255, 1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_112 {
    overflow: visible;
    position: absolute;
    width: 12.5px;
    height: 12.523px;
    left: 5.5px;
    top: 6.503px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Rectangle_130 {
    fill: rgba(255, 246, 237, 1);
}

.Rectangle_130 {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.071));
    position: absolute;
    overflow: visible;
    width: 135px;
    height: 74px;
    left: 327px;
    top: 480.5px;
}

#ID000_e {
    left: 352px;
    top: 495.5px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(27, 28, 29, 1);
}

#Rectangle_124 {
    fill: rgba(245, 247, 250, 1);
}

.Rectangle_124 {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 40px;
    left: 270px;
    top: 381.5px;
}

#ID30 {
    left: 280px;
    top: 388.5px;
    position: absolute;
    overflow: visible;
    width: 20px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#Group_64 {
    position: absolute;
    width: 17.416px;
    height: 17.416px;
    left: 1550px;
    top: 672px;
    overflow: visible;
}

#Group_66 {
    position: absolute;
    width: 17.416px;
    height: 17.416px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Ellipse_8 {
    fill: rgba(255, 255, 255, 1);
}

.Ellipse_8 {
    position: absolute;
    overflow: visible;
    width: 17px;
    height: 17px;
    left: 0px;
    top: 0px;
}

#Group_54 {
    position: absolute;
    width: 17.416px;
    height: 17.415px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Group_48 {
    position: absolute;
    width: 7.182px;
    height: 2.421px;
    left: 5.117px;
    top: 11.358px;
    overflow: visible;
}

#Path_116 {
    fill: rgba(27, 28, 29, 1);
}

.Path_116 {
    overflow: visible;
    position: absolute;
    width: 7.182px;
    height: 2.421px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_49 {
    position: absolute;
    width: 17.416px;
    height: 17.415px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Path_117 {
    fill: rgba(27, 28, 29, 1);
}

.Path_117 {
    overflow: visible;
    position: absolute;
    width: 17.416px;
    height: 17.416px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_50 {
    position: absolute;
    width: 1px;
    height: 2px;
    left: 4.584px;
    top: 6px;
    overflow: visible;
}

#Ellipse_6 {
    fill: rgba(27, 28, 29, 1);
}

.Ellipse_6 {
    position: absolute;
    overflow: visible;
    width: 1px;
    height: 2px;
    left: 0px;
    top: 0px;
}

#Group_51 {
    position: absolute;
    width: 1px;
    height: 2px;
    left: 11.584px;
    top: 6px;
    overflow: visible;
}

#Ellipse_7 {
    fill: rgba(27, 28, 29, 1);
}

.Ellipse_7 {
    position: absolute;
    overflow: visible;
    width: 1px;
    height: 2px;
    left: 0px;
    top: 0px;
}

#Group_52 {
    position: absolute;
    width: 2.271px;
    height: 1.514px;
    left: 3.786px;
    top: 3.786px;
    overflow: visible;
}

#Path_118 {
    fill: rgba(27, 28, 29, 1);
}

.Path_118 {
    overflow: visible;
    position: absolute;
    width: 2.271px;
    height: 1.514px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_53 {
    position: absolute;
    width: 2.271px;
    height: 1.514px;
    left: 11.358px;
    top: 3.787px;
    overflow: visible;
}

#Path_119 {
    fill: rgba(27, 28, 29, 1);
}

.Path_119 {
    overflow: visible;
    position: absolute;
    width: 2.271px;
    height: 1.514px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_65 {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 1351px;
    top: 672px;
    overflow: visible;
}

#Ellipse_9 {
    fill: rgba(255, 255, 255, 1);
}

.Ellipse_9 {
    position: absolute;
    overflow: visible;
    width: 18px;
    height: 17px;
    left: 0px;
    top: 1px;
}

#Group_55 {
    position: absolute;
    width: 17.957px;
    height: 17.957px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Group_60 {
    position: absolute;
    width: 17.957px;
    height: 17.957px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Path_124 {
    fill: rgba(27, 28, 29, 1);
}

.Path_124 {
    overflow: visible;
    position: absolute;
    width: 17.957px;
    height: 17.957px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_61 {
    position: absolute;
    width: 10.15px;
    height: 5.465px;
    left: 3.904px;
    top: 10.15px;
    overflow: visible;
}

#Path_125 {
    fill: rgba(27, 28, 29, 1);
}

.Path_125 {
    overflow: visible;
    position: absolute;
    width: 10.15px;
    height: 5.465px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_62 {
    position: absolute;
    width: 3.904px;
    height: 1.694px;
    left: 2.342px;
    top: 6.246px;
    overflow: visible;
}

#Path_126 {
    fill: rgba(27, 28, 29, 1);
}

.Path_126 {
    overflow: visible;
    position: absolute;
    width: 3.904px;
    height: 1.694px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_63 {
    position: absolute;
    width: 3.904px;
    height: 1.694px;
    left: 11.711px;
    top: 6.246px;
    overflow: visible;
}

#Path_127 {
    fill: rgba(27, 28, 29, 1);
}

.Path_127 {
    overflow: visible;
    position: absolute;
    width: 3.904px;
    height: 1.694px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_69 {
    position: absolute;
    width: 1181px;
    height: 671px;
    left: 110px;
    top: 801px;
    overflow: visible;
}

#Rectangle_179 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_179 {
    filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.059));
    position: absolute;
    overflow: visible;
    width: 1229px;
    height: 707px;
    left: 0px;
    top: 12px;
}

#Rectangle_178 {
    fill: rgba(255, 246, 237, 1);
}

.Rectangle_178 {
    position: absolute;
    overflow: visible;
    width: 1181px;
    height: 49px;
    left: 0px;
    top: 0px;
}

#CALL_CENTER {
    left: 60px;
    top: 13px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
    text-transform: uppercase;
}

#Count_By_Value {
    left: 32px;
    top: 70px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#ID350k_300k_250k_200k_150k_100 {
    left: 32px;
    top: 134px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#L1 {
    left: 130px;
    top: 614px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#L1_f {
    left: 499px;
    top: 614px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#L1_ga {
    left: 866px;
    top: 614px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#L1_gb {
    left: 254px;
    top: 614px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#L1_gc {
    left: 621px;
    top: 614px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#L1_gd {
    left: 991px;
    top: 614px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#L1_ge {
    left: 377px;
    top: 614px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#L1_gf {
    left: 744px;
    top: 614px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#L1_gg {
    left: 1112px;
    top: 614px;
    position: absolute;
    overflow: visible;
    width: 15px;
    white-space: nowrap;
    line-height: 63px;
    margin-top: -24.5px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Group_67 {
    position: absolute;
    width: 28.691px;
    height: 23.948px;
    left: 22px;
    top: 13px;
    overflow: visible;
}

#Path_128 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.20000000298023224px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_128 {
    overflow: visible;
    position: absolute;
    width: 20.728px;
    height: 16.937px;
    left: 4.294px;
    top: 7.211px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_129 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.20000000298023224px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_129 {
    overflow: visible;
    position: absolute;
    width: 10.375px;
    height: 8.815px;
    left: 9.444px;
    top: 12.578px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_130 {
    fill: rgba(27, 28, 29, 1);
    stroke: rgba(27, 28, 29, 1);
    stroke-width: 0.20000000298023224px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_130 {
    overflow: visible;
    position: absolute;
    width: 28.896px;
    height: 10.259px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_131 {
    fill: transparent;
    stroke: rgba(216, 216, 216, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_131 {
    overflow: visible;
    position: absolute;
    width: 1063px;
    height: 472.833px;
    left: 77px;
    top: 124.894px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Rectangle_180 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_180 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 236px;
    left: 105px;
    top: 361px;
}

#Rectangle_187 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_187 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 311px;
    left: 592px;
    top: 286px;
}

#Rectangle_183 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_183 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 40px;
    left: 349px;
    top: 557px;
}

#Rectangle_189 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_189 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 23px;
    left: 136px;
    top: 68px;
}

#Rectangle_185 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_185 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 269px;
    left: 835px;
    top: 328px;
}

#Rectangle_181 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_181 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 138px;
    left: 228px;
    top: 459px;
}

#Rectangle_186 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_186 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 199px;
    left: 714px;
    top: 398px;
}

#Rectangle_182 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_182 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 164px;
    left: 471px;
    top: 433px;
}

#Rectangle_184 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_184 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 91px;
    left: 959px;
    top: 506px;
}

#Rectangle_188 {
    fill: rgba(11, 77, 96, 1);
}

.Rectangle_188 {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 20px;
    left: 1079px;
    top: 577px;
}

#Rectangle_25 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_25 {
    position: absolute;
    overflow: visible;
    width: 1787px;
    height: 84px;
    left: 110px;
    top: 98px;
}

#Practice_Manager_Dashboard {
    left: 130px;
    top: 124px;
    position: absolute;
    overflow: visible;
    width: 315px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: rgba(34, 37, 42, 1);
}

#Component_7__1 {
    position: absolute;
    width: 249px;
    height: 45px;
    left: 1472px;
    top: 117px;
    overflow: visible;
}

#Rectangle_26 {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(236, 238, 241, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_26 {
    position: absolute;
    overflow: visible;
    width: 249px;
    height: 45px;
    left: 0px;
    top: 0px;
}

#Denton {
    left: 12px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#Path_81 {
    fill: rgba(226, 228, 231, 1);
}

.Path_81 {
    overflow: visible;
    position: absolute;
    width: 15.068px;
    height: 8.218px;
    left: 221.491px;
    top: 18px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Component_5__3 {
    position: absolute;
    width: 249px;
    height: 45px;
    left: 1207px;
    top: 117px;
    overflow: visible;
}

#Component_4__1 {
    position: absolute;
    width: 249px;
    height: 45px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Rectangle_26_g {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(236, 238, 241, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_26_g {
    position: absolute;
    overflow: visible;
    width: 249px;
    height: 45px;
    left: 0px;
    top: 0px;
}

#Component_5__1_g {
    position: absolute;
    width: 145px;
    height: 24px;
    left: 12px;
    top: 11px;
    overflow: visible;
}

#Regional_Manager {
    opacity: 0.5;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 146px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(34, 37, 42, 1);
}

#Path_81_g {
    fill: rgba(226, 228, 231, 1);
}

.Path_81_g {
    overflow: visible;
    position: absolute;
    width: 15.068px;
    height: 8.218px;
    left: 221.491px;
    top: 18px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_82 {
    fill: transparent;
    stroke: rgba(236, 238, 241, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_82 {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 56.856px;
    left: 624px;
    top: 111.5px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_8 {
    position: absolute;
    width: 142px;
    height: 45px;
    left: 1737px;
    top: 117px;
    overflow: visible;
}

#Rectangle_26_ha {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(34, 133, 208, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Rectangle_26_ha {
    position: absolute;
    overflow: visible;
    width: 105px;
    height: 45px;
    left: 0px;
    top: 0px;
}

#Path_83 {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(15, 15, 15, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_83 {
    overflow: visible;
    position: absolute;
    width: 46.152px;
    height: 45px;
    left: 95.848px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Component_5__1_hb {
    position: absolute;
    width: 71px;
    height: 21px;
    left: 12px;
    top: 10px;
    overflow: visible;
}

#Schedules {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 72px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(34, 133, 208, 1);
    text-decoration: underline;
}

#ID0_hd {
    left: 116px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 10px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(27, 28, 29, 1);
}

#Group_68 {
    position: absolute;
    width: 105px;
    height: 45px;
    left: 1336px;
    top: 381px;
    overflow: visible;
}

#Rectangle_26_hf {
    fill: rgba(74, 136, 218, 1);
}

.Rectangle_26_hf {
    position: absolute;
    overflow: visible;
    width: 68px;
    height: 45px;
    left: 0px;
    top: 0px;
}

#Path_83_hg {
    fill: rgba(255, 246, 237, 1);
}

.Path_83_hg {
    overflow: visible;
    position: absolute;
    width: 46.152px;
    height: 45px;
    left: 58.848px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Component_5__1_hh {
    position: absolute;
    width: 36px;
    height: 21px;
    left: 12px;
    top: 10px;
    overflow: visible;
}

#Tasks {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(224, 248, 255, 1);
    text-decoration: underline;
}

#ID2 {
    left: 79px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 10px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(27, 28, 29, 1);
}

#Rectangle_78 {
    fill: rgba(255, 246, 237, 1);
}

.Rectangle_78 {
    position: absolute;
    overflow: visible;
    width: 138px;
    height: 36px;
    left: 457px;
    top: 122px;
}

#ID20052021 {
    left: 475px;
    top: 126px;
    position: absolute;
    overflow: visible;
    width: 103px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112, 112, 112, 1);
}

#Group_10 {
    position: absolute;
    width: 160px;
    height: 36px;
    left: 652px;
    top: 122px;
    overflow: visible;
}

#Rectangle_79 {
    fill: rgba(74, 136, 218, 1);
}

.Rectangle_79 {
    position: absolute;
    overflow: visible;
    width: 119px;
    height: 36px;
    left: 0px;
    top: 0px;
}

#Rectangle_80 {
    fill: rgba(32, 122, 191, 1);
}

.Rectangle_80 {
    position: absolute;
    overflow: visible;
    width: 42px;
    height: 36px;
    left: 118px;
    top: 0px;
}

#Maria_Carrera {
    left: 9px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
}

#PM {
    left: 126px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
}

#Group_9 {
    position: absolute;
    width: 192px;
    height: 36px;
    left: 826px;
    top: 122px;
    overflow: visible;
}

#Rectangle_82 {
    fill: rgba(253, 205, 86, 1);
}

.Rectangle_82 {
    position: absolute;
    overflow: visible;
    width: 150px;
    height: 36px;
    left: 0px;
    top: 0px;
}

#Rectangle_81 {
    fill: rgba(215, 150, 50, 1);
}

.Rectangle_81 {
    position: absolute;
    overflow: visible;
    width: 42px;
    height: 36px;
    left: 150px;
    top: 0px;
}

#Cecilia_Pattersopn {
    left: 9px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 131px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
}

#RM {
    left: 158px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
}

#Group_11 {
    position: absolute;
    width: 25px;
    height: 27px;
    left: 582px;
    top: 105px;
    overflow: visible;
}

#Ellipse_5 {
    fill: rgba(212, 116, 47, 1);
}

.Ellipse_5 {
    position: absolute;
    overflow: visible;
    width: 25px;
    height: 24px;
    left: 0px;
    top: 3px;
}

#Group_26 {
    position: absolute;
    width: 1935.5px;
    height: 78.5px;
    left: 0px;
    top: -19px;
    overflow: visible;
}

#Rectangle_2 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_2 {
    filter: drop-shadow(8px 3px 17px rgba(0, 0, 0, 0.071));
    width: 78.5px;
    height: 1935.5px;
    position: absolute;
    overflow: visible;
    transform: translate(0px, 19px) matrix(1, 0, 0, 1, 928.5, -947.5) rotate(90deg);
    transform-origin: center;
}

#Repeat_Grid_1 {
    position: absolute;
    width: 17.106px;
    height: 15px;
    left: 110.213px;
    top: 39.92px;
    overflow: hidden;
}

#Repeat_Grid_1_0 {
    position: absolute;
    width: 17.106px;
    height: 1px;
    left: 0px;
    top: 1.5px;
    overflow: visible;
}

#Path_1 {
    fill: transparent;
    stroke: rgba(215, 125, 63, 1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_1 {
    overflow: visible;
    position: absolute;
    width: 17.106px;
    height: 3px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Repeat_Grid_1_1 {
    position: absolute;
    width: 17.106px;
    height: 1px;
    left: 0px;
    top: 7.5px;
    overflow: visible;
}

#Path_1_h {
    fill: transparent;
    stroke: rgba(215, 125, 63, 1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_1_h {
    overflow: visible;
    position: absolute;
    width: 17.106px;
    height: 3px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Repeat_Grid_1_2 {
    position: absolute;
    width: 17.106px;
    height: 1px;
    left: 0px;
    top: 13.5px;
    overflow: visible;
}

#Path_1_ia {
    fill: transparent;
    stroke: rgba(215, 125, 63, 1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Path_1_ia {
    overflow: visible;
    position: absolute;
    width: 17.106px;
    height: 3px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_1 {
    position: absolute;
    width: 4px;
    height: 14px;
    left: 1879px;
    top: 40px;
    overflow: visible;
}

#Ellipse_1 {
    fill: rgba(61, 69, 83, 1);
}

.Ellipse_1 {
    position: absolute;
    overflow: visible;
    width: 4px;
    height: 4px;
    left: 0px;
    top: 0px;
}

#Ellipse_2 {
    fill: rgba(61, 69, 83, 1);
}

.Ellipse_2 {
    position: absolute;
    overflow: visible;
    width: 4px;
    height: 4px;
    left: 0px;
    top: 5px;
}

#Ellipse_3 {
    fill: rgba(61, 69, 83, 1);
}

.Ellipse_3 {
    position: absolute;
    overflow: visible;
    width: 4px;
    height: 4px;
    left: 0px;
    top: 10px;
}

#Tynoe_Creates {
    left: 1731px;
    top: 40px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(34, 37, 42, 1);
}

#Group_2 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 1696px;
    top: 35px;
    overflow: visible;
}

#Ellipse_4 {
    fill: rgba(51, 105, 31, 1);
}

.Ellipse_4 {
    position: absolute;
    overflow: visible;
    width: 27px;
    height: 27px;
    left: 0px;
    top: 0px;
}

#T {
    left: 9px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 9px;
    white-space: nowrap;
    text-align: left;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
}

#Group_25 {
    position: absolute;
    width: 90px;
    height: 1759px;
    left: -3px;
    top: -31px;
    overflow: visible;
}

#Rectangle_1_ii {
    fill: url(#Rectangle_1_ii);
}

.Rectangle_1_ii {
    filter: drop-shadow(8px 3px 17px rgba(0, 0, 0, 0.071));
    position: absolute;
    overflow: visible;
    width: 141px;
    height: 1810px;
    left: 0px;
    top: 0px;
}

#Group_24 {
    position: absolute;
    width: 62px;
    height: 77px;
    left: 17px;
    top: 65px;
    overflow: visible;
}

#Rectangle_27 {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_27 {
    position: absolute;
    overflow: visible;
    width: 62px;
    height: 77px;
    left: 0px;
    top: 0px;
}

#Group_7 {
    position: absolute;
    width: 45.192px;
    height: 52.631px;
    left: 8px;
    top: 12px;
    overflow: visible;
}

#Group_3 {
    position: absolute;
    width: 45.192px;
    height: 52.631px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Path_2 {
    fill: rgba(0, 0, 0, 0);
}

.Path_2 {
    overflow: visible;
    position: absolute;
    width: 24.616px;
    height: 20.604px;
    left: 8.574px;
    top: 16.014px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_3 {
    fill: rgba(0, 0, 0, 0);
}

.Path_3 {
    overflow: visible;
    position: absolute;
    width: 24.616px;
    height: 20.604px;
    left: 8.574px;
    top: 16.014px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_4_iq {
    fill: url(#Path_4_iq);
}

.Path_4_iq {
    overflow: visible;
    position: absolute;
    width: 31.512px;
    height: 52.631px;
    left: 13.68px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_5 {
    fill: rgba(0, 0, 0, 0);
}

.Path_5 {
    overflow: visible;
    position: absolute;
    width: 24.616px;
    height: 20.604px;
    left: 8.574px;
    top: 16.014px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_6_it {
    fill: url(#Path_6_it);
}

.Path_6_it {
    overflow: visible;
    position: absolute;
    width: 23.439px;
    height: 14.046px;
    left: 13.68px;
    top: 7.439px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_7_iv {
    fill: url(#Path_7_iv);
}

td.sizeNwd {
    font-size: 11px;
}

.Path_7_iv {
    overflow: visible;
    position: absolute;
    width: 23.573px;
    height: 14.589px;
    left: 13.68px;
    top: 30.603px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_8 {
    fill: rgba(11, 77, 96, 1);
}

.Path_8 {
    overflow: visible;
    position: absolute;
    width: 32.535px;
    height: 36.301px;
    left: 0px;
    top: 8.165px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

/* Progress Bar */
.Pmdprogress {
    width: 100%;
}

.pdmitem {
    cursor: pointer;
}

.pmd_numbers_clickable {
    background: #f6f8fc 0% 0% no-repeat padding-box;
    color: orange;
    font-size: 18px !important;
    border-radius: 0px 0px 4px 4px;
    opacity: 1;
}

.pmd_tbl_numbers_clickable {
    color: orange;
    font-size: 18px !important;
    border-radius: 0px 0px 4px 4px;
    cursor: pointer;
}

.pcr_tbl_numbers_clickable {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.pmdbold {
    font-weight: bold;
}

.w-100 {
    width: 100%;
}

.ui.table thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
}

.text-right {
    text-align: right
}

.sub-name {
    font-size: x-small;
}

.text-muted {
    color: #777;
}

.udr-icon {
    background-image: url('../images/LOUPES IconAsset 1.svg');
}

.mtdclctable {
    max-width: 2000px;
    width: 100%;
    zoom: 75%;
    font-size: 10px;
}

.mtdclctable td {
    width: auto;
    overflow: hidden;
    word-wrap: break-word;
    white-space: nowrap;
}

.whiteSpace {
    white-space: pre;
}

.helpMarkLeftAlign {
    display: flex;
    justify-Content: start;
}

.dentist-list .Select--multi .Select-control .Select-value {
    display: inline-block !important;
}

.report_help {
    display: inline;
}

.report_help .icon.info {
    color: #fff !important;
}

.customReferralTable {
    border-collapse: separate !important;
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: .28571429rem;
    text-align: left;
}

.customReferralTable .fixedCellWidth {
    min-width: 350px !important;
    position: fixed;
}

.customReferralTable td.title {
    background: #ffa905 !important;
    font-weight: 600;
}

.customReferralTable tr:nth-child(even) {
    background: #DDDDDD;
}


/*---------------- REFERRAL DATA OS PRACTICES ------------------------ */

.filterContainer h2 {
    margin-top: 5px;
}

.referralsFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.referralsFilters a {
    color: black;
}

.month-year-picker {
    position: relative;
}

.month-year-picker .react-datepicker-wrapper {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;

}

.month-year-picker .react-datepicker-wrapper .react-datepicker__input-container {
    text-align: center;
}

.month-year-picker .calendar-icon {
    position: absolute;
    height: 100%;
    z-index: 1;
    right: 10px;
    top: 12px;
    color: #666 !important;
}

.hidden {
    display: none !important;
}

.text-center {
    text-align: center !important;
}

.tr-nowrap > th {
    white-space: nowrap;
}

.limit-height {
    height: 50vh;
}

.smaller-table {
    font-size: 10px;
    margin: 0;
}

.smaller-table thead th {
    padding: 0;
    min-width: 0;
}

.container-fluid div.ui.global-error {
    position: absolute;
    right: 10%;
    width: 30%;
    z-index: 2;
    box-shadow: 3px 4px #cbcbcb;
}

.popup-content {
    height: 400px;
    background-color: whitesmoke;
    border-radius: 8px;
    padding: 1rem;
    overflow: scroll;
    transition: height 500ms ease;
}

.row-edit {
    cursor: pointer;
}

.row-edit .row-icon {
    opacity: 0 !important;
}

.row-edit:hover .row-icon {
    opacity: 100 !important;
    margin-left: 5px;
}

/*
*
*   Lighthouse Page styles
*
 */
.performance-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.performance-section > .score {
    background: dodgerblue;
    padding: 5px;
    border-radius: 5px;
    color: whitesmoke;
}

.displayScores {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.displayScores .container {
    width: 100%;
}

.displayScores .container .platform-title {
    font-size: 26px;
    color: #0c5460;
    font-variant: petite-caps;
}

.scoresResults {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.scoresResults .score-item-big {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.scoresResults .score-item-small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.seperator {
    border-right: 1px solid lightslategray;
    margin-right: 20px;
}

/*
*            lds-roller
*   LOADER FOR TABLE COLUMN SECTIONS
*               START
 */
.lds-facebook {
    display: inline-block;
    position: absolute;
    /*width: 80px;*/
    height: 2px;
    top: -40px;
    left: -10px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #c6c6c6;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0.01s;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
*            lds-roller
*   LOADER FOR TABLE COLUMN SECTIONS
*               END
 */

.button.btn-rerun-lambda {
    color: black;
    font-weight: bold !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.button.btn-rerun-lambda.active {
    background: #176b82;
    color: white;
}

.button.btn-list-all-lambdas {
    color: black;
    font-weight: bold !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.button.btn-list-all-lambdas.active {
    background: #176b82;
    color: white;
}

span.or-seperator {
    position: absolute;
    background: white;
    font-size: 15px;
    padding: 0 5px;
    border-radius: 50%;
    transform: translate(-18px, 5px);
    color: gray;
}


ul.providersTab {
    list-style-type: none !important;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.providersTab li {
    float: left;
}

.providersTab li a {
    display: block;
    color: #7aa4ea;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.providersTab li a:hover {
    color: #072a31;
}

.providersTab li.active {
    background-color: #e0e0e052;
}

.ellipsis {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.ui.fullscreen.modal.downtime {
    left: unset !important;
}

.modal-downtime-content {
    display: flex !important;
    justify-content: space-around;
    flex-direction: row;
    gap: 10px;
}

.modal-downtime-content .listview {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 5px;
    width: 50%;
    padding: 10px;
}

.modal-downtime-content .listview .apps-heading {
    font-size: 16px;
    color: darkorange;
}

.apps-down-list-view {
    list-style: none;
}

.listview {
    max-height: 60vh;
    overflow: scroll;
}

.app-down-heading h4 {
    font-size: 15px;
    font-variant: petite-caps;
    margin: 10px 5px;
    color: rgba(255, 140, 0, .7);
    border: 1px solid rgba(255, 140, 0, .2);
    border-radius: 5px;
    padding: 5px;
}

.app-down-heading h4 span.count {
    float: right;
    background: rgba(0, 0, 0, .2);
    color: rgba(255, 255, 255, .8);
    padding: 0 5px;
    border-radius: 50%;
}

.app-down-list-item a {
    cursor: pointer;
}

.app-down-list-item a.active {
    color: rgba(255, 140, 0, .7);
}

.app-down-list-item a.active:before {
    border: 2px solid rgba(255, 140, 0, .5) !important;
    background: rgba(255, 140, 0, .5);
}

.app-down-list-item a:hover {
    color: rgba(255, 140, 0, .7);
}

.app-down-list-item a:hover:before {
    border: 1px solid rgba(255, 140, 0, .5) !important;
}

.ui.progress.red-bg {
    background: #DB2828;
}

.app-down-list-item a {
    position: relative;
    font-size: 12px;
    margin-left: 50px;
    padding: 5px 0;
}

.app-down-list-item a:before {
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid rgba(0, 0, 0, .3);
    border-radius: 50%;
    position: absolute;
    left: -15px;
    top: 9px;
}

/*.app-down-list-item a:after {*/
/*    content: '';*/
/*    height: 100%;*/
/*    border: 1px solid rgba(255, 140, 0, .2);*/
/*    position: absolute;*/
/*    left: -30px;*/
/*    top: 0;*/
/*}*/

.modal-downtime-content .details-view .details-view-heading {
    font-size: 16px;
    color: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.modal-downtime-content .details-view .details-view-heading > span {
    font-weight: 100;
    color: black;
    font-style: italic;
}

.modal-downtime-content .details-view {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 5px;
    width: 100%;
    padding: 10px;
}

.modal-downtime-content .details-view .details {
    position: relative;
}

.modal-downtime-content .details-view .details p.sticks {
    position: absolute;
    background: slategrey;
    right: 0;
    top: 10px;
    padding: 10px 20px;
    color: white;
    z-index: 2;
}

.modal-downtime-content .details-view .details p.sticks:after {
    content: '';
    z-index: 1;
    position: absolute;
    right: -10px;
    bottom: -10px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    transform: rotate(90deg);
    border-top: 20px solid slategrey;
}

.modal-downtime-content .details-view .details p.sticks .ticketNo1 {
    color: rgba(255, 255, 255, .6);
}

.modal-downtime-content .details-view .details p.sticks .ticketNo2 {
    color: white;
}

.modal-downtime-content .details-view .details .ticketNo1 {
    color: rgba(0, 0, 0, .5);
}

.modal-downtime-content .details-view .details .ticketNo2 {
    color: rgba(0, 0, 0, .8);
    font-weight: bold;
}

.modal-downtime-content .details-view .details .box {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;
    padding: 25px 5px 5px 5px;
}

.modal-downtime-content .details-view .details .box .header {
    position: absolute;
    top: -10px;
    left: 10px;
    background: steelblue;
    padding: 5px 10px;
    color: white;
}


span.icon-text {
    display: inline-block;
    margin-right: 5px !important;
}

span.icon-text i.icon {
    margin-right: 5px !important;
}


/** Start section - Websites dashboard redesign */
.poppins-regular * {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;;
}

.poppins-bold {
    font-family: "Poppins", sans-serif !important;;
    font-weight: 700 !important;
    font-style: normal !important;;
}

.poppins-black {
    font-family: "Poppins", sans-serif !important;
    font-weight: 900 !important;
    font-style: normal !important;
}

.aws-websites-root {
}

.aws-websites-root .websites-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0px;
}

.aws-websites-root .websites-header .card {
    height: 10rem;
    display: flex;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 20px rgba(5, 5, 5, 0.1);
    cursor: pointer;
}

.aws-websites-root .websites-header .card:hover {
    box-shadow: 0 0 20px rgba(5, 5, 5, 0.18);
    transform: scale(102%);
}

.aws-websites-root .websites-header .card .card-content {
    flex-grow: 1;
}

.aws-websites-root .websites-header .card .card-icon svg {
    height: 4rem;
    width: 4rem;
}

.aws-websites-root .websites-header .card .card-number {
    font-size: 2rem;
    font-weight: bolder;
    margin-top: 0;
    position: relative;
}

.aws-websites-root .websites-header .card .card-number span {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 30px;
    box-sizing: content-box;
    padding: 0 0.5rem;
}

.aws-websites-root .websites-header .card .card-number span.up {
    color: #82ff82;
}

.aws-websites-root .websites-header .card .card-number span.down {
    color: #ff8282;
}

.aws-websites-root .websites-data-tables-section:not(:first-child):not(.in-grid) {
    margin-top: 6rem;
}

.aws-websites-root .websites-data-tables-section {
    padding: 2rem;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 20px rgba(5, 5, 5, 0.1)
}

.aws-websites-root .websites-data-tables-section > h1 {
    color: black;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.aws-websites-root .websites-data-tables-section ul.table-selector-list {
    list-style: none;
}

.aws-websites-root .websites-data-tables-section ul.table-selector-list li {
    display: inline-block;
    margin-right: 1rem;
    cursor: pointer;
}


.aws-websites-root .websites-data-tables-section ul.table-selector-list li a {
    text-decoration: none;
    color: black;
}

.aws-websites-root h1,
.aws-websites-root h2,
.aws-websites-root h3,
.aws-websites-root h1 a,
.aws-websites-root h2 a,
.aws-websites-root h3 a {
    color: black;
}

.aws-websites-root .websites-data-tables-section ul.table-selector-list li.active,
.aws-websites-root .highlighted-label {
    background: #d78831;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 700 !important;
    color: white;
}

.inline-block {
    display: inline-block;
}

.aws-websites-root .websites-data-tables-section ul.table-selector-list li.active a {
    color: white;
}

.aws-websites-root .website-stats-table {
    margin-top: 2rem;
}


.aws-websites-root .website-stats-table td.table-status-online {
    color: green;
}

.aws-websites-root .website-stats-table td.table-status-offline {
    color: red;
}

.aws-websites-root .website-stats-table td.status,
.aws-websites-root .website-stats-table td.actions {
    text-align: center;
}

.aws-websites-root .website-stats-table td svg {
    height: 2rem;
}

.aws-websites-root .website-stats-table td.actions a,
.aws-websites-single.failure a {
    background: #d78831;
    color: white !important;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
}

.aws-websites-root .website-stats-table td.actions a {
    margin-right: 10px;
}

.aws-websites-root .website-stats-table td.actions a.is-danger {
    background: #e73131;
}

.aws-websites-root .website-stats-table td.actions a.has-icon svg,
.aws-websites-single.failure a.has-icon svg {
    color: white !important;
    height: 1rem;
    margin-right: .5rem;
}

.aws-websites-root h1 > svg {
    height: 1.5rem;
    color: black;
    margin-right: .5rem;
    margin-left: .5rem;

}

.aws-websites-single.failure {
    height: calc(100vh - 7rem);
    background: white;
    padding: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black !important;

    flex-direction: column;
    gap: 5rem;
}

.aws-websites-single.failure h1 {
    color: black;
}

.aws-websites-root .table-top-actions {
    float: right;
}

.aws-websites-root .table-top-actions a {
    margin-left: 1rem;
    color: black;
    transition-duration: 50ms !important;
}

.aws-websites-root .table-top-actions a:hover, .aws-websites-root .table-top-actions a.highlighted {
    background: #d78831;
    color: white !important;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    cursor: pointer
}

.aws-modal .close_modal_link {
    font-size: 1.5rem;
    position: absolute;
    right: 4rem;
    top: 2.5rem;
    color: black;
}

.aws-modal .close_modal_link a {
    color: black;
    display: inline-block;
    font-weight: 400 !important;
}

.aws-modal .close_modal_link a:hover {
    color: grey;
}

.aws-modal .field label {
    font-family: "Poppins", sans-serif;
    font-weight: 500 !important;
}

.aws-websites-root .website-stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-auto-rows: 1fr;
    gap: 2rem;
}

.aws-websites-root .stat-grid {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.aws-websites-root .stat-card {
    padding: 3rem;
    border: 1px solid lightgray;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aws-websites-root .stat-card h4 {
    font-weight: bolder !important;
    font-size: large
}

.performance-chart {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 20px solid lightgray;
    /* Base border for empty space */
    position: relative;
}

.performance-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 20px;
}

/* Generic invalid value styling (optional) */
.invalid-value {
    color: gray;
}

.performance-color-0 {
    color: red;
}

.performance-color-1 {
    color: orange;
}

.performance-color-2 {
    color: yellow;
}

.performance-color-3 {
    color: yellowgreen;
}

.performance-color-4 {
    color: green;
}

.mt-small {
    margin-top: 2rem;
}

.mt-md {
    margin-top: 4rem !important;
}

.mr-xs {
    margin-right: 1rem;
}

.pb-sm {
    padding-bottom: 2rem !important;
}

.w-half {
    width: 50% !important;
    display: inline-block;
}


.aws-websites-root ul.website-practices {
    list-style: none;
    padding: 0;
    margin: 0;
}

.aws-websites-root ul.website-practices li {
    display: inline-block;
    background-color: #d78831;
    color: white;
    margin-right: 6px;
    padding: 2px 3px;
    border-radius: 1rem;
}

.aws-modal {
    font-family: ;
    padding: 2rem;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 20px rgba(5, 5, 5, 0.1);
}

body.dimmed .aws-websites-root {
    filter: blur(2px)
}

.aws-websites-root .table-search {
    padding-top: 3rem;
    margin-bottom: -1rem;
}

.aws-websites-root .table-search input {
    box-shadow: 0 0 10px rgb(0 0 0 / 27%);
    padding: .8rem .5rem;
    border-radius: 2rem;
    width: 300px;
}

.aws-websites-root .table-search input:focus {
    outline: none;
    box-shadow: 0 0 10px rgb(0 0 0 / 46%);
}

/* End section - websites dashboard redesign */

.sticky-subheader {
    position: sticky !important;
    top: 24px !important;
    z-index: 9 !important;
}

/* ThreeStageProgressBar.css */
.progress-container {
    width: 100%;
}

.progress-bar {
    display: flex;
    height: 30px;
    width: 100%;
    background-color: #e0e0df;
    border-radius: 5px;
    overflow: hidden;
    height: 1.8em;

}

.progress-bar-stage {
    height: 100%;
    transition: width 0.3s ease-in-out;
}

.scraper-view {
    cursor: pointer;
    position: absolute;
    color: white;
    top: 2px;
    right: 5px;
    font-weight: 900;
    font-size: 15px;
}

.scraper-view:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    transition: box-shadow 0.3s ease;
    background-color: whitesmoke;
}

.helpList {
    padding-left: 15px;
}

.helpList li strong {
    font-size: 14px !important;
}

.helpList li + li {
    margin-top: 15px;
    border-top: 1px solid #e1e1e1;
    padding-top: 10px;
}

/* Styles for the chat button */
.chat-button {
    position: fixed;
    bottom: 20px;
    right: 70px; /* Increase this value to move it left */
    background-color: #2563eb;
    color: white;
    padding: 12px;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

/* Hover effect */
.chat-button:hover {
    background-color: #1e40af;
    transform: scale(1.1);
}

.helpVideo {
    max-width: 100%;
}

.helpVideo + .helpVideo {
    margin-top: 20px;
}

.pt-3 {
    padding-top: 1.5rem;
}

.pl-3 {
    padding-left: 1.5rem;
}

.tabContent {
    position: relative !important;
    background: none !important;
    padding: 0 !important;
}

.table-ellipsis{
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.tab.segment {
    border: none;
    box-shadow: none;
    background: transparent;
}

.ui.tabular.menu {
    margin-bottom: 1em;
}
.custom-tabs .ui.secondary.pointing.menu .active.item {
    border-color: #2185d0 !important;  /* This is Semantic UI's primary blue color */
    color: #2185d0 !important;
}

.custom-tabs .ui.secondary.pointing.menu .item {
    margin-bottom: -2px;
}

.custom-tabs .ui.secondary.pointing.menu .item:hover {
    border-color: rgba(33, 133, 208, 0.5) !important;  /* Lighter version of primary color for hover */
    color: #2185d0 !important;
}