/* PDFPreview.css */
.pdf-preview-container {
  padding: 20px;
  /* max-width: 1400px; */
  margin: 0 auto;
}

/* Header Card */
.header-card {
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background: #f8f9fa;
}

.header-card .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px !important;
}

.header-content {
  display: inline;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.page-title {
  margin: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333;
  float: left !important;
}

.notification-badges {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Tab Navigation */
.report-type-menu {
  margin-bottom: 20px !important;
  border-bottom: 1px solid rgba(34,36,38,.15);
  background: none;
}

.pcr_tbl_numbers_clickable {
  cursor: pointer;
  padding: 12px 20px !important;
  font-size: 14px;
  font-weight: 500;
  color: #555;
  border-radius: 6px 6px 0 0;
  transition: all 0.3s;
}

.pcr_tbl_numbers_clickable:hover {
  background: #f0f0f0;
  color: #2185d0;
}

.pcr_tbl_numbers_clickable.active {
  font-weight: 600;
  color: #2185d0;
  border-bottom: 3px solid #2185d0;
  background: rgba(33,133,208,0.05);
}

/* Content Card */
.content-card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background: white;
}

.content-wrapper {
  padding: 0 !important;
}

.columns-container {
  display: flex;
  height: calc(100vh - 280px);
  min-height: 500px;
}

/* Documents Column */
.documents-column {
  width: 45%;
  padding: 20px;
  border-right: 1px solid #f0f0f0;
  overflow-y: auto;
  background: #fafafa;
}

.section-header {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.document-list {
  height: calc(100% - 40px);
  overflow-y: auto;
  padding-right: 5px;
}

.file-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.file-item {
  padding: 14px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  transition: all 0.3s;
  background: white;
  border: 1px solid #eee;
  position: relative;
  overflow: hidden;
}

.file-item:hover {
  border-color: #2185d0;
  box-shadow: 0 2px 8px rgba(33,133,208,0.1);
}

.file-item.attached {
  background: #f0f9f0;
  border-color: #a0d8a0;
  background-color: #f6fff6;
  border-left: 3px solid #21ba45;
}

.file-icon {
  margin-right: 12px;
  color: #2185d0;
  font-size: 18px;
}

.file-details {
  flex: 1;
}

.file-name {
  font-weight: 500;
  color: #333;
}

.file-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.85em;
  color: #777;
  margin-top: 4px;
}

/* Email Column */
.email-column {
  width: 55%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: white;
}

.email-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}

.email-form {
  margin-bottom: 20px;
}

.email-form .field {
  margin-bottom: 16px;
}

.email-form label {
  font-weight: 500;
  color: #555;
  margin-bottom: 6px;
}

.attachments-section {
  margin-bottom: 15px;
  flex: 1;
  overflow-y: auto;
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.attachments-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.attachment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  transition: background 0.2s;
}

.attachment-item:hover {
  background: #f9f9f9;
}

.send-button-container {
  text-align: right;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .columns-container {
      height: auto;
      flex-direction: column;
  }
  
  .documents-column,
  .email-column {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #eee;
  }
  
  .header-content {
      flex-wrap: wrap;
      gap: 15px;
  }
  
  .date-picker-input {
      width: 100%;
  }
  
  .notification-badges {
      width: 100%;
      justify-content: flex-end;
  }
}

.pcr_tbl_numbers_clickable {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.topFilters {
  float: right;
}

.topFilters > div,
.topFilters button {
  margin-right: 10px !important;
  margin-bottom: 15px !important;
}

.topFilters > *:last-child {
  margin-right: 0 !important;
}

.date-picker-input:focus {
  border-color: #2185d0;
  outline: none;
  box-shadow: 0 0 0 2px rgba(33,133,208,0.2);
}

/* DatePicker Styles */
.date-picker-container {
  position: relative;
  display: inline-block;
}

.date-picker-input {
  padding: 8px 30px 8px 12px !important;
  border: 1px solid #d4d4d5 !important;
  border-radius: 4px !important;
  height: 38px !important;
  min-width: 180px;
  font-size: 14px !important;
}

.date-picker-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: rgba(0,0,0,.4);
}

/* Ensure the popup appears above all other elements */
.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker {
  font-family: inherit !important;
  z-index: 9999 !important;
  box-shadow: 0 2px 10px rgba(0,0,0,0.15) !important;
}

.notification-popup {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  max-width: 350px;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.notification-popup .ui.message {
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}

/* Recipients Input Styles */
.recipients-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  padding: 8px;
  border: 1px solid #d4d4d5;
  border-radius: 4px;
  min-height: 42px;
  background: white;
}

.recipients-input {
  flex: 1;
  min-width: 120px;
  border: none;
  outline: none;
  padding: 4px;
  font-size: 14px;
}

.recipients-input:focus {
  outline: none;
}

.recipient-chip {
  display: inline-flex;
  align-items: center;
  background-color: #e1f0fa;
  color: #1a73e8;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 14px;
  height: 28px;
}

.recipient-chip.invalid {
  background-color: #fce8e6;
  color: #d93025;
}

.recipient-remove-icon {
  margin-left: 6px;
  cursor: pointer;
  font-size: 12px;
  opacity: 0.7;
}

.recipient-remove-icon:hover {
  opacity: 1;
}

/* Modern input focus style */
.ui.input>input:focus {
  border-color: #1a73e8;
  box-shadow: 0 0 0 1px #1a73e8;
}

/* Fullscreen Modal Styles */
.fullscreen-modal {
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  border-radius: 0 !important;
}

.fullscreen-modal-content {
  padding: 20px !important;
  height: calc(100vh - 120px) !important;
  display: flex !important;
  flex-direction: column !important;
}

.fullscreen-modal-actions {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  background: white !important;
  padding: 15px 20px !important;
  border-top: 1px solid #ddd !important;
  display: flex !important;
  justify-content: flex-end !important;
  gap: 10px !important;
}

/* PDF Preview Modal Specific Styles */
.pdf-preview-modal .header {
  position: fixed;
  top: 5;
  left: 5;
  right: 5;
  z-index: 100;
  background: white;
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
}

.pdf-zoom-controls {
  position: fixed;
  top: 70px;
  right: 20px;
  z-index: 100;
  background: rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.pdf-iframe-container {
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 60px;
  height: calc(100% - 120px);
}

.pdf-iframe-container iframe {
  border: none;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

/* Error File Styles */
.file-item.error-file {
  border-left: 3px solid #db2828;
  background-color: #fff6f6;
}

.file-item.error-file:hover {
  background-color: #ffe8e8;
}

.error-badge {
  margin-left: 8px;
  padding: 2px 6px;
  background-color: #db2828;
  color: white;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
}

/* Button Colors */
.ui.red.button {
  background-color: #db2828 !important;
  color: white !important;
}

.ui.green.button {
  background-color: #21ba45 !important;
  color: white !important;
}

.ui.button:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.error-file {
  background-color: #fff6f6;
  border-left: 3px solid #db2828;
}

.error-file:hover {
  background-color: #ffe8e8;
}

/* New verified styles */
.file-item.verified-file {
  background-color: #f6fff6;
  border-left: 3px solid #21ba45;
}

.file-item.verified-file:hover {
  background-color: #e8ffe8;
}

.verified-badge {
  background-color: #21ba45;
  color: white;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-left: 8px;
}

/* Existing attachment badge */
.attachment-badge {
  position: absolute;
  top: 0;
  right: 0;
  background: #4caf50;
  color: white;
  padding: 2px 6px;
  border-radius: 0 0 0 4px;
  font-size: 12px;
}

/* File icon colors */
.file-icon .icon.red {
  color: #db2828 !important;
}

.file-icon .icon.green {
  color: #21ba45 !important;
}